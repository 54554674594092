@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

#tivityClassesWidget {
  .styles-module--button--16yHE,
  .styles-module--button--16yHE:active,
  .styles-module--button--16yHE:focus,
  .styles-module--button--16yHE:hover {
    background-color: red;
  }
}
.logo-containter {
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-basis: 0;
  font-size: 1.5rem;
  animation: fadein 1s;
  .logo-shoe {
    max-width: 40px;
    margin-right: 10px;
    flex: 1 1;
  }
  .logo-text {
    flex: 1 1;
    .logo-header {
      text-transform: uppercase;
      font-family: normal normal bold 11px/15px Roboto;
      font-size: 1em;
      letter-spacing: 0.88px;
      line-height: 0.9em;
      margin: 0;
      span {
        display: block;
        text-align: left;
        font: normal normal bold 1em/15px Roboto;
        letter-spacing: 0.88px;
        color: #50aebd;
        opacity: 1;
      }
    }
    .logo-sub-header {
      text-align: left;
      letter-spacing: 0.88px;
      color: #757575;
      font-size: 0.3em;
      opacity: 1;
      i {
        letter-spacing: -0.008em;
        // font-family: $logo-text-together;
      }
      span {
        font-size: 0.3em;
        vertical-align: super;
      }
    }
    .logo-shadow {
      display: none;
      position: relative;
      margin-left: -2.5em;
      margin-top: -0.6em;
      z-index: -50;
      max-width: 9em;
    }
  }
  @media screen and (min-width: 992px) {
    .logo-text .logo-sub-header {
      display: block;
    }
    .logo-text .logo-shadow {
      display: block;
    }
    .logo-shoe {
      max-width: 30%;
      margin-right: 0;
    }
  }
}
.feature-base {
  [class="data-feature-index=4"] {
    margin-top: 10px;
  }

  $self: &;
  position: relative;
  // height: 440px;
  z-index: 1;
  @media only screen and (min-width: 768px) {
    height: 540px;
  }
  @media only screen and (min-width: 1092px) {
    height: 640px;
  }
  @media only screen and (min-width: 1400px) {
    height: 740px;
  }
  @media only screen and (min-width: 1750px) {
    height: 840px;
  }
  @media only screen and (min-width: 1900px) {
    height: 940px;
  }
  @media only screen and (min-width: 20000px) {
    height: 940px;
  }
  &--large {
    height: 675px;
  }
  &--xs {
    height: 300px;
  }
  &__background {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-origin: content-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @media only screen and (max-width: 600px) {
      display: none;
    }
    &--slanted {
      padding: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--color-overlay {
      background-color: var(--accent);
      background-blend-mode: screen;
    }
  }
  &__child-container {
    height: 100%;
    display: flex;
    align-items: stretch;
    @media only screen and (max-width: 600px) {
      margin: 15px 0;
    }

    .feature-base__child {
      @media only screen and (min-width: 600px) {
        flex: 1;
      }
    }
  }
}

.feature-base:nth-of-type(4) {
  margin-top: 20px;
}

.feature-mobile-container {
  margin-top: 45px;
  @media only screen and (max-width: 430px) {
    height: 520px;
    overflow: hidden;
  }
}

.feature-mobile {
  li {
    list-style: "\2713";
  }

  @media only screen and (max-width: 430px) {
    max-width: 70% !important;
  }
}

.feature-base-mobile {
  padding-top: 65px;
  @media only screen and (max-width: 430px) {
    height: 675px;
    display: block;
  }
}
.family-memberships-sup {
  font-size: 10px;
}
.feature-digital-content {
  background-color: #f5f5f5;
  padding-top: 51px;
  margin-top: 40px;
}
.feature-digital-content + .feature-digital-content {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 24px;
  margin-top: 0px;
  margin-bottom: 45px;
  padding-bottom: 35px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.feature-base__child.odd {
  img {
    margin-bottom: 15px;
    @media only screen and (max-width: 430px) {
      height: 200px;
      display: none;
    }
  }
}

.feature-content {
  max-width: 60%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: #000000;
    font-weight: bold;
  }
  img {
    padding-top: 10px;
  }
  h4 {
    padding-top: 2rem;
  }
  ul {
    margin-top: 1rem;
  }
  li::marker {
    color: #50aebd;
    font-size: 25px;
    vertical-align: middle;
  }
  .p {
    margin-left: -5px;
  }
  //Code to divide the list into two columns
  // ul:has(li:nth-child(4)) {
  //   columns: 2;
  // }
  li {
    list-style: disc;
    line-height: 1.2;
  }
  .app-store-button-container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 430px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &--large {
    // max-width: 75%;
    max-width: 100%;
  }
  &__attribute {
    display: flex;
    align-items: center;
    width: 350px;
  }
  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // background: #106bb5 !important;
    // color: var(--contrastTextPrimary) !important;
    background: var(--landingPageButton) !important;
    padding: 16px;
    font-size: 16px;
    max-width: 180px;
    white-space: nowrap;
    height: 48px;
    &--contrast {
      background: #ffffff !important;
      // color: var(--clientPrimary) !important;
      border-color: #ffffff !important;
      color: var(--featurePrimary) !important;
    }
    &--inverted {
      // background: #ffffff !important;
      border-color: #ffffff !important;
    }
    &--large {
      font-size: 1.1rem;
      padding: 16px 24px;
      max-width: 250px;
    }
    &:hover {
      transition: filter 0.3s;
      filter: brightness(85%);
    }
  }

  .embedded-entry-container a {
    display: inline-block;
    margin-right: 10px; /* Optional: Add some spacing between links */
  }

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    align-items: center;
    p {
      max-width: 90%;
    }
  }
}

.chevron-right {
  height: 17px;
  width: 18px;
  margin-left: 5px;
}

.chevron-right-success {
  height: 17px;
  width: 8px;
  margin-left: 11px;
}

.feature-testimonial-content {
  $self: &;
  position: relative;
  align-self: flex-end;
  height: 200px;
  width: 100%;
  z-index: 1;
  &__slanted-container {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
  &__accent-container {
    position: absolute;
    left: 0;
    top: 7%;
    height: 86%;
    width: 100%;
    z-index: 2;
  }
}

.slanted-background {
  position: relative;
  background: #f2f2f2;
  height: 100%;
  margin-left: 60px;
  &::after {
    content: "";
    position: absolute;
    top: -15%;
    left: -20%;
    width: 35%;
    height: 130%;
    opacity: 1;
    filter: alpha(opacity=80); /* For IE8 and earlier */
    // background: #f2f2f2;
    background: green;
    transform: skew(-25deg, 0deg);
    z-index: 999;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    background: none !important;
    margin-left: -17px;
  }
  button {
    @media only screen and (max-width: 600px) {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      font-size: 15px !important;
    }
  }
}

@mixin slanted-feature {
  position: relative;
  display: inline-block;
  padding: 1em 5em 1em 1em;
  overflow: hidden;
  height: 100%;
  @media only screen and (max-width: 600px) {
    padding: 1em;
    text-align: center;
  }
  h1 {
    color: #ffffff;
    font-size: 56px;
    line-height: 64px;
  }
  h2,
  h3,
  h4,
  p,
  a {
    color: #ffffff;
  }
  a {
    font-size: 0.8rem;
    padding: 8px 0px;
    &:hover {
      color: #fff;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--primary);
    color: #fff !important;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    z-index: -1;
    @content;
    @media only screen and (max-width: 600px) {
      transform: none;
    }
  }
  &--default {
    min-width: 400px;
    width: 120%;
    @media only screen and (max-width: 600px) {
      min-width: auto;
      width: auto;
    }
  }
  &--large {
    width: 150%;
  }
  &--white-feature {
    margin-top: 25px;
    height: 86%;
    width: 80%;
    h1,
    h2,
    h3,
    h4,
    p {
      margin-left: 20px;
      color: $text !important;
      margin-top: -15px;
    }
    p:last-of-type {
      margin-top: 20px;
      font-size: 12px;
    }

    div {
      position: relative;
      top: 0;
      left: 30%;
      max-width: 70%;
      height: 100%;
      padding: 12px;
      width: 80%;
      z-index: 5;
    }
    &::after {
      background: #ffffff !important;
      opacity: 0.8;
    }
  }
  &__accent-feature {
    &::after {
      background: var(--testimonialAccent) !important;
      opacity: 0.8;
    }
  }
  &__secondary-feature {
    &::after {
      background: var(--testimonialGreen) !important;
    }
  }
  &__bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: white;
    z-index: -1;
  }
}

.small {
  width: 65% !important;
}

.testimonial-slanted-feature-right {
  margin-top: -110px;
  width: 90%;
  opacity: 0.9;
  @include slanted-feature {
    z-index: -999;
    background-color: white;
    -webkit-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    transform: skew(-25deg);
  }
  overflow: visible;
  &__accent-container {
    z-index: 0;
    width: 110%;
    opacity: 0.9;
    height: 100%;
    position: absolute;
    left: 0;
    top: 7%;
    height: 86%;
    width: 100%;
    z-index: 2;
  }
}

.slanted-feature-right {
  @include slanted-feature {
    background: var(--featurePrimary) !important;
    -webkit-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    transform: skew(-25deg);
  }
  overflow: visible;
  &__accent-container {
    position: absolute;
    left: 0;
    top: 7%;
    height: 86%;
    width: 100%;
    z-index: 2;
  }
}
.slanted-feature-right::before {
  content: "";
  width: 120%;
  height: 90%;
  position: absolute;
  right: 135px;
  top: 50px;
  background: var(--slant);
  opacity: 0.75;
  z-index: -1;
  -webkit-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);

  @media only screen and (max-width: 1290px) {
    // width: 160px;
  }

  @media only screen and (max-width: 1130px) {
    display: none;
  }
}

.slanted-feature-right-testimonial {
  @include slanted-feature {
    background: #106bb5 !important;
    -webkit-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    transform: skew(-25deg);
  }
  overflow: visible;
  &__accent-container {
    position: absolute;
    left: 0;
    top: 7%;
    height: 86%;
    width: 100%;
    z-index: 2;
  }
}
.slanted-feature-right-testimonial::before {
  content: "";
  width: 120%;
  height: 90%;
  position: absolute;
  right: 106px;
  top: 37px;
  // background: var(--walmartYellow);
  opacity: 0.75;
  z-index: -1;
  -webkit-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);

  @media only screen and (max-width: 1290px) {
    // width: 160px;
  }

  @media only screen and (max-width: 1130px) {
    display: none;
  }
}

.success-container {
  background: #f5f5f5;
  height: 110%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.success-content {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  background: #f5f5f5;
}

.success-image {
  width: 80%;
}

.success-title {
  font-size: 44px;
  padding: 20px;
  font-weight: 900;
}

.user-email {
  padding-top: 10px;
  color: black;
  margin-bottom: 10px;
}

.member-card-container {
  border: solid;
  border-width: 0.5px;
  border-color: #dedede;
  border-radius: 5px;
  max-width: 40%;
  padding: 13px;
  background: white;
  margin-top: 50px;
  @media (max-width: 768px) {
    max-width: 80%;
  }
}

.member-card-container-text {
  padding-bottom: 20px;
}

.member-card-link {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  padding-bottom: 20px;
}

.background-container {
  position: relative;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130%;
  z-index: -1;
}

.wellness-ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.wellness-ul li {
  font-size: 1.1rem;
  width: 50%;
  box-sizing: border-box;
}

.wellness-i {
  font-size: 1.1rem;
}

.wellness-h3 {
  font-size: 1.3rem;
}

.feature-wellness {
  position: absolute;
}

.wlc-logo {
  width: 300px;
  height: auto;
}

.what-can-you-expect-ul li {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font: italic normal normal 24px/27px Roboto;
  letter-spacing: 0.24px;
  opacity: 1;
  list-style: disc;
}

.slanted-content {
  position: relative;
  background: white;
  padding: 20px;
  transform: skewX(35deg);
  transform-origin: 0;
  width: 150%;
}

.slanted-content-slanted-1 {
  background: var(--testimonialGreen);
  width: 45%;
  height: 400px;
  padding-top: 350px;
  margin-top: 150px;
  margin-left: -2200px;
  // margin-left: calc(-3000px + 100vw) !important;
  transform: skewX(-30deg) scaleX(-1);
  transform-origin: 203%;
}

.slanted-content-slanted-2 {
  background: var(--testimonialGreen);
  width: 40%;
  padding-top: 320px;
  margin-top: -350px;
  margin-left: -1870px;
  // margin-left: calc(-2735px + 100vw) !important;
  transform: skewX(-30deg) scaleX(-1);
  transform-origin: 203%;
  opacity: 0.75;
}
.testimonial-subbody {
  font-size: 12px;
  margin-left: 50px;
  width: auto;
  padding-top: 135px;
  padding-bottom: 10px;
}

.testimonial-body-content {
  max-width: 80%;
  display: flex;
  align-items: center;
  margin-top: 20;
  margin-left: 300px;
  color: black;
}

.testimonial-p {
  color: black !important;
  margin-top: -50px;
  p {
    color: black !important;
  }
}

.testimonial-i {
  margin-top: 150px;
  margin-left: 420px;
}

.blueQuotes {
  height: 100px;
  margin-right: 15px;
  margin-top: -15px;
}
.large-quote {
  margin-top: -15px;
  left: -90px;
  position: absolute;
}

.what-can-you-expect-boxes {
  z-index: 2;
  position: relative;
  text-align: left;
  font: normal normal 900 40px/46px Roboto;
  letter-spacing: 0.4px;
  color: #333333;
  padding: 20px 40px 20px 69px;
  width: auto;
}

.what-can-you-expect-boxes:before {
  content: "";
  width: 120%;
  height: 90%;
  position: absolute;
  right: 15%;
  left: -14%;
  top: 0;
  background: #fff;
  transform: skew(-55deg, 0deg);
  z-index: -1;
}
.what-can-you-expect-boxes:after {
  content: "";
  width: 120%;
  height: 70%;
  position: absolute;
  right: 10%;
  left: -7%;
  top: 0px;
  background: #0078bf;
  transform: skew(-55deg, 0deg);
  z-index: -2;
  margin-top: 10px;
  margin-bottom: 20px;
}

.video-section {
  margin-bottom: 150px;
}

.video-container-row {
  margin-left: -20px;
}

.wistia_embed {
  width: 600px;
  height: 340px;
  margin-left: 850px;
  margin-top: -375px;
  margin-right: 0;
  position: relative;
  z-index: 1;
}

.testimonial-cover {
  background-size: cover;
  background-position: center;
}

.testimonial-background-image {
  width: 100%;
}
.testimonial-container {
  margin-top: -450px;
}

.slanted-feature-left {
  @include slanted-feature {
    -webkit-transform: skew(35deg);
    -ms-transform: skew(35deg);
    transform: skew(35deg);
  }
}

.bottom-feature {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  width: 100%;
  background: var(--featurePrimary);
  padding: 24px;
  padding-bottom: 36px !important;
  button {
    margin-top: 12px;
  }
  h3 {
    font-size: 20px;
    color: #ffffff;
    line-height: 28px;
  }
  h1 {
    color: white; //going to have to set this to something like text-secondary
    text-align: center;
  }
  .contact-info {
    margin-top: 22px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_message {
      font: normal normal bold 20px/28px Roboto;
      letter-spacing: 0.2px;
    }
    &_number {
      font: normal normal normal 20px/28px Roboto;
      letter-spacing: 0.2px;
    }
    &_window {
      font: normal normal normal 14px/28px Roboto;
      letter-spacing: 0.14px;
    }
  }
}
.bottom-feature-no-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--primary);
  padding-top: 10px;
  padding-bottom: 10px;
  // padding: 24px;
  button {
    margin-top: 12px;
  }
  h2,
  p {
    color: white; //going to have to set this to something like text-secondary
    text-align: center;
  }
}

.landing-page-nav-bar {
  z-index: 9999;
}

.legal-disclaimer {
  $self: &;
  min-height: 150px;
  width: 100%;
  background: var(--legalDarkBlue);
  padding: 24px 36px 36px 144px;
  color: white;
  @media (min-width: 300px) {
    padding: 24px 40px 36px 40px;
  }
  @media (min-width: 425px) {
    padding: 24px 50px 36px 50px;
  }
  @media (min-width: 768px) {
    padding: 24px 100px 36px 100px;
  }
  @media (min-width: 1024px) {
    padding: 24px 150px 36px 150px;
  }
  @media (min-width: 1440px) {
    padding: 24px 150px 36px 150px;
  }
  h2 {
    p {
      color: white;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &__header-container {
    width: 100%;
    white-space: nowrap;
    display: flex;
    h4 {
      color: white;
    }
    hr {
      display: inline-block;
      width: 100%;
      position: relative;
      bottom: 4px;
      margin-left: 8px;
      height: 2px;
      border: none;
      color: white;
      background-color: white;
      opacity: 1;
    }
  }
  &__flex {
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    width: 100%;
    // max-height: 325px;
    flex-wrap: wrap;
    padding: 0px;
    #{ $self }__flex-item {
      padding-right: 1.5rem !important;
      width: 50%;
      margin-top: 10px;
      @media (min-width: 768px) {
        padding: 4px;
      }
      p {
        font-size: 12px;
        color: #ffffff;
      }
      span {
        content: "\2022";
      }
    }
  }
}

.remove-edge {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 12px 24px;
  color: #797979;
  background: #e8e8e8;
  border: #797979 1px solid;
}

.tab-pane {
  padding: 12px;
  .feature-content {
    h2 {
      font: normal normal 900 36px/40px Roboto;
    }
  }
}

.grid {
  $self: &;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: repeat(3, 1fr);
  &__stepper {
    grid-row-start: span 3;
    grid-column: 1;
    justify-self: center;
    height: 100%;
    position: relative;
  }
  &__line {
    position: absolute;
    width: 40px;
    height: calc(100% - 74px);
    left: 50%;
    margin-left: -20px;
    background: var(--primary);
    margin-top: 37px;
  }
  &__feature {
    grid-column: 2;
  }
  &__circle {
    width: 74px;
    height: 74px;
    background: var(--primary) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    opacity: 1;
  }
  .feature-base__background {
    height: 90%;
  }
}

.footer-main {
  font-size: 14px;
  text-align: center;
  padding: 0px 0px;
  background: var(--footer);

  p {
    color: white;
    a {
      color: white !important;
      font-size: 12px;
    }
    a:hover {
      color: white !important;
    }
  }

  &__hcsc {
    font-size: 14px;
    text-align: center;
    padding: 0px 0px;
    background: var(--footer);
    margin-top: -40px;
    p {
      color: white;
      a {
        color: white !important;
        font-size: 12px;
      }
      a:hover {
        color: white !important;
      }
    }
  }
}

/* overrieding library styling*/
.slide-pane__overlay.overlay-after-open {
  z-index: 10000;
}

.btn-primary {
  -webkit-box-shadow: 2px 2px 8px -5px #000000;
  box-shadow: 2px 2px 8px -5px #000000;
}

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  vertical-align: middle;
  background: #e53935;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 32px;
  color: #fff;
  z-index: 4;
}

.debug-side {
  width: 400px;
  transition: width ease 0.3s;
}

.debug-close {
  width: 0px;
}
.billing-container {
  .nav-tabs {
    border-bottom: none;
    display: flex;
    :hover {
      text-decoration: none;
    }
    .nav-link {
      margin-top: -1rem;
      margin-bottom: 0.8rem;
      text-align: left; //EDITED CODE
      flex: 1;
      // color: black; //EDITED
      font-size: 1rem;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      border: none;
    }
    .nav-link.active {
      font-weight: 700;
      background-color: unset;
      text-decoration: none;
      border: none;
      border-bottom: 2px solid #f5f5f5; // EDITED CODE
    }
  }
}

.nav-link-bold {
  font-weight: bolder;
}

// Set to Overide Bootstrap CSS
.table-row {
  color: grey;
  font-size: 0.8rem;
}

.handleClickBurnalong {
  color: var(--primary);
}

.how-it-works-nav-container {
  background-color: #f5f5f5;
  padding-top: 50px;
  .nav-container-header {
    // color: var(--primary);
    text-align: center;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    line-height: 32px;
  }
  .nav-tabs {
    margin-left: 98px;
    margin-top: 2rem;
    border-bottom: none;
    :hover {
      text-decoration: none;
    }
    .nav-link {
      color: var(--howItWorksNav);
      font-size: 16px;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      border: none;
    }
    .nav-link.active {
      font-weight: bold;
      background-color: unset;
      text-decoration: none;
      border: none;
      border-bottom: 5px solid var(--howItWorksNav);
    }
  }
}

.slide-pane {
  &__content {
    padding: 0px 0px !important;
  }
  &__title-wrapper {
    margin-left: 0;
  }
}

.flyin {
  .slide-pane_header {
    font: normal normal normal 20px/22px Roboto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 2rem;
    padding-right: 2rem;
    .slide-pane__title {
      white-space: unset;
      overflow: visible;
      text-overflow: unset;
      font: normal normal bold 25px/26px Roboto;
      letter-spacing: 0.25px;
      color: #333333;
    }
  }
}

.slide-pane {
  .slide-pane__title {
    font-size: 17px;
  }
}

.flyin-button-container {
  position: sticky;
  display: flex;
  padding: 16px 24px;
  justify-content: space-around;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #f5f5f5;
  -webkit-box-shadow: 0px -1px 7px -3px rgba(0, 0, 0, 0.55);
  box-shadow: 0px -1px 7px -3px rgba(0, 0, 0, 0.55);
  .nav-btn-enroll {
    font: normal normal bold 17px/20px Roboto;
    width: auto;
  }
  .nav-btn-enroll-change {
    font: normal normal bold 17px/20px Roboto;
    width: auto;
  }
}
.bar {
  list-style: none;
  width: 74px;
  padding: 0;
  &__secondary {
    position: absolute;
    top: 0;
    left: 10px;
  }
  &__item {
    position: relative;
    margin-top: 220px;
    &:first-child {
      margin-top: 116px;
    }
    &:last-of-type::after {
      height: 0px;
    }
  }
  &__item::before {
    font-size: 20px;
  }
  &__item::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    border-left: 32px solid var(--primary);
    margin-left: 21px;
    height: 300px;
  }
  &__accent::after {
    border-left: 32px solid var(--secondary);
  }
}

.learn-more {
  color: #1099d6 !important;
  position: absolute;
  bottom: -50px;
  left: 50%;
  text-align: center;
  text-transform: capitalize;
  font-style: italic;
  color: var(--primary);
  margin-bottom: -15px;
}

.footer-card {
  .package-button {
    @media (max-width: 1170px) {
      margin-left: 0px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  margin-top: 24px;
  z-index: 3;
  // box-shadow: 0px 2px 8px #dddddd;
}
.footer-card-non-fixed {
  width: 100%;
  height: 100px;
  margin-top: 24px;
  z-index: 3;
  box-shadow: 0px 2px 8px #dddddd;
}

.wizard-step {
  &__container {
    padding: 16px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  &__disabled {
    margin-right: 24px;
    font-size: 14px;
    color: #757575;
  }
  &__selected {
    display: inline-block;
    margin-right: 24px;
    color: var(--clientPrimary);
    font-size: 14px;
  }
  &__unselected {
    display: inline-block;
    margin-right: 24px;
    font-size: 14px;
    color: var(--clientPrimary);
  }
  @media (max-width: 992px) {
    &__disabled {
      font-size: 0.9rem;
      margin-right: 12px;
    }
    &__selected {
      color: var(--primary);
      font-size: 1rem;
      margin-right: 12px;
    }
    &__unselected {
      color: var(--primary);
      font-size: 0.9rem;
      margin-right: 12px;
    }
  }
  @media (max-width: 576px) {
    &__disabled {
      font-size: 0.8rem;
      margin-right: 12px;
    }
    &__selected {
      color: var(--primary);
      font-size: 0.9rem;
      margin-right: 12px;
    }
    &__unselected {
      color: var(--primary);
      font-size: 0.8rem;
      margin-right: 12px;
    }
  }
}

.wizard-footer {
  &__button {
    font: normal normal bold 17px/20px Roboto;
    width: auto;
    white-space: nowrap;
    width: 100%;
    padding: 20px 0px;
    max-width: 280px;
  }
  @media (min-width: 576px) {
    &__button {
      padding: 20px 32px;
    }
  }
}

.members-eligibility-form-container {
  margin-top: 20px;
}

.eligibility-form-container {
  .card-title {
    text-transform: uppercase;
    font-size: 18px;
    color: var(--primary);
  }
  .required-field-marker {
    color: #d9534f;
    margin-left: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .card {
    border: 1px solid #0000001f;
    border-radius: 5px;
  }
}

.add-family-member-form-container {
  padding: 16px;
  &__button {
    width: 100%;
    color: white;
    margin: 30px 0;
    display: block;
    padding: 15px;
    text-align: center;
    transition: background-color 0.2s cubic-bezier(0, 0.2, 0.8, 1);
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    background-color: #072c4c;

    &:hover {
      color: var(--primary);
      text-decoration: underline;
      background-color: #3a5478;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
  }

  &__details {
    border: solid 1px #ddd;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold !important;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 16px;
    color: #666;
  }

  .required-field-marker {
    color: #d9534f;
    margin-left: 2px;
  }
}

.horizontal-line {
  margin: 1rem;
  display: inline-block;
  width: 29.5vw;
  height: 0.15rem;
  background-color: var(--light);
}

.helper-card {
  display: flex;
  border: var(--primary) 1px solid;
  background: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 8px;
  max-width: 750px;
  align-items: flex-start;
  h6 {
    display: inline;
    font-style: italic;
    color: var(--primary);
    font-size: 0.8rem;
  }
  section {
    color: var(--text);
    font-style: italic;
    font-size: 0.8rem;
  }
  &__content {
    margin-left: 4px;
  }
  &__badge {
    margin-top: 2px;
    background: var(--primary);
    border-radius: 50%;
    min-width: 14px;
    min-height: 14px;
    font-size: 14px;
    font-weight: bolder;
    color: #fff;
    text-align: center;
    line-height: 14px;
  }
}

.input-group-button {
  background: var(--primary);
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0px;
  min-width: 24px;
  @media screen and (max-width: 1024px) {
    max-width: 10px;
  }
}

.notification-container {
  position: absolute;
  top: 75px;
  width: 100%;
  z-index: 2;
  left: 1px;
}
.alert-button {
  color: #ffffff;
  background: none;
  padding: 0;
  border: none;
  float: right;
  font-weight: bold;
  font-size: 25px;
  margin: -40px 10px 0 0;
}

.feature-package-select {
  background: #ffffff;
  padding: 100px;
  text-align: center;
}
.custom-modal-ul {
  li {
    list-style-image: url("../resources/gradient_bullet_point.svg");
    margin-bottom: 15px;
    p {
      position: relative;
      left: 20px;
    }
  }
}
.contentful-modal {
  text-align: center;
  justify-content: center;
  flex-direction: column;
  .redemption-eligibility {
    font-size: 25px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // color: var(--contrastTextPrimary);
  }
  &__checkmark {
    flex-shrink: 0;
    display: inherit;
    margin-bottom: 12px;
  }
  &__checkmark::after {
    content: url("../resources/check-mark-2.svg");
    font-size: 60px;
    line-height: 73px;
    color: var(--primary);
    min-width: 73px;
    min-height: 73px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 50%;
  }
  &__exclamation {
    flex-shrink: 0;
    display: inherit;
    margin-bottom: 12px;
  }
  &__exclamation::after {
    content: "!";
    font-weight: 1000;
    font-size: 60px;
    line-height: 73px;
    color: var(--primary);
    min-width: 73px;
    min-height: 73px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 50%;
  }
  @media (max-width: 576px) {
    &__checkmark::after {
      font-size: 50px;
      line-height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
    h2 {
      font-size: 1.3rem;
    }
  }
}

.modal-header-form {
  background: var(--clientPrimary);
  color: var(--contrastTextPrimary);
  text-align: center;
  height: 174px;
  justify-content: center;
  flex-direction: column;
  .redemption-eligibility {
    font-size: 25px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--contrastTextPrimary);
  }
  &__checkmark {
    flex-shrink: 0;
    display: inherit;
    margin-bottom: 12px;
  }
  &__checkmark::after {
    content: url("../resources/check-mark-2.svg");
    font-size: 60px;
    line-height: 73px;
    color: var(--clientPrimary);
    min-width: 73px;
    min-height: 73px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 50%;
  }
  &__exclamation {
    flex-shrink: 0;
    display: inherit;
    margin-bottom: 12px;
  }
  &__exclamation::after {
    content: "!";
    font-weight: 1000;
    font-size: 60px;
    line-height: 73px;
    color: var(--primary);
    min-width: 73px;
    min-height: 73px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 50%;
  }
  @media (max-width: 576px) {
    &__checkmark::after {
      font-size: 50px;
      line-height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
    h2 {
      font-size: 1.3rem;
    }
  }
}

.modal-header-form-partner {
  background: none;
  color: var(--text);
  white-space: nowrap;
  border-bottom: 1px solid #0000001f;
  padding-top: 25px;
  padding-bottom: 10xp;
  span {
    margin-top: -60px;
    margin-right: -5px;
  }
  b {
    font-size: 25px;
    margin-left: 10px;
    white-space: break-spaces;
  }
  @media (max-width: 576px) {
    h2 {
      font-size: 1.3rem;
    }
  }
}

.modal-body-form {
  padding: 0px;
  padding: 40px 80px 20px 80px;
  text-align: center;
  .required-field-marker {
    color: #d9534f;
    margin-right: 2px;
  }
  .redemption-body {
    margin-top: 50px;
    margin-bottom: -20px;
  }
  .redemption-confirmed {
    font-size: 18px;
  }
  .redemption-proceed {
    font-size: 16px;
  }
  .redemption-agreement {
    font-size: 16px;
  }
  .redemption-tnc {
    font-size: 12px;
  }
  h6 {
    text-align: center;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #333333;
  }
  @media (max-width: 992px) {
    padding: 20px 40px 20px 40px;
  }
}

.modal-body-form-partner {
  padding: 20px 30px 20px 30px;
  border-bottom: 1px solid #0000001f;
  h6 {
    text-align: left;
    margin-bottom: 25px;
    font-size: 14px;
    color: #404040;
  }
  p {
    text-align: left;
  }
  small {
    color: #585858;
    font-size: 12px;
    font-style: italic;
  }
  @media (max-width: 992px) {
    padding: 20px 20px 20px 20px;
  }
}

.status-modal {
  min-width: 634px;
  @media (max-width: 992px) {
    min-width: 500px;
  }
  @media (max-width: 576px) {
    min-width: 90%;
  }
}

.modal-footer-form {
  .redemption-button {
    width: 380px;
    height: 60px;
    padding: 15px 12px 15px 12px;
    font-size: 18px;
    font-weight: bold;
  }
  justify-content: center;
  &__button {
    background-color: var(--modalButton);
    color: var(--contrastTextPrimary);
    // width: 380px;
    width: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: var(--modalButton);
      color: var(--contrastTextPrimary);
      cursor: pointer;
    }
    &:disabled {
      background-color: var(--modalButton);
      color: var(--contrastTextPrimary);
      cursor: pointer;
    }
  }
  &__close_button {
    background-color: var(--secondary);
    color: var(--primary);
    border-radius: var(--bs-btn-border-radius);
    border-width: var(--bs-btn-border-width);
    border-color: var(--primary);
    // width: 380px;
    width: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: var(--hoverButtonColor);
      transition: all 0.3s ease;
      border-color: var(--primary);
      color: var(--primary);
      cursor: pointer;
    }
    &:disabled {
      background-color: var(--modalButton);
      color: var(--contrastTextPrimary);
      cursor: pointer;
    }
  }
}

.modal-body-fw-text {
  margin-right: 1rem;
  margin-left: 1rem;
}

.modal-footer-form-partner {
  justify-content: center;
  padding-bottom: 20px;
  flex-wrap: nowrap;
  &__button {
    width: 250px;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 10px;
    &__zuora {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: #069;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .stay-here {
    margin-right: 65px;
    @media (max-width: 992px) {
      margin-right: 10px;
    }
  }
}

.wizard-container {
  .header-component {
    margin-top: 10px;
  }
}

.dropdown-form {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #666666;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #f00;
}
.zuora-pricing-package {
  text-align: center;
  flex-direction: column;
  .luxury-gyms {
    margin-top: 12px;
    margin-right: 5px;
    margin-left: 5px;
    border: #82b265;
    border-color: #82b265;
    border-style: solid;

    border-width: 1.5px;
    margin-bottom: 12px;
    .lux-ct {
      // opacity: 0.25;
      background-color: rgba(130, 178, 101, 0.25);
    }
  }
  .standard-gyms {
    .std-ct {
      font-weight: bold;
      font: normal normal bold 14px/18px Roboto;
      color: #000;
      margin-bottom: 0;
      margin-top: 5px;
    }
    .std-des {
      font: italic normal 14px/18px Roboto;
      color: #a9a9a9;
      margin-bottom: 12px;
    }
  }
  hr {
    margin-left: 0%;
    margin-right: 0%;
  }
  p {
    font-size: 14px;
    color: #757575;
    line-height: 20px;
  }
}
.extras {
  margin-top: 10px;
  .digital {
    font-weight: bold;
    font: normal normal bold 14px/18px Roboto;
    color: #000;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .studio {
    font-weight: bold;
    font: normal normal bold 14px/18px Roboto;
    color: #000;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .dig-des {
    font: italic normal 14px/18px Roboto;
    color: #a9a9a9;
    margin-bottom: 12px;
  }
  .stu-des {
    font: italic normal 14px/18px Roboto;
    color: #a9a9a9;
    margin-bottom: 12px;
  }
}
// .extras {
//   margin-top: 10px;
//   h4 {
//     font-weight: bold;
//     font: normal normal bold 14px/18px Roboto;
//     color: #000;
//     margin-bottom: 0;
//     margin-top: 5px;
//   }
//   p:first-of-type {
//     margin-top: 0;
//     font: normal normal 14px/18px Roboto;
//   }
//   p {
//     font: italic normal 14px/18px Roboto;
//     color: #a9a9a9;
//     margin-bottom: 12px;
//   }
// }
.feature-package-select {
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    max-width: 860px;
    margin: 52px auto;
    justify-items: center;
    // align-items: flex-start;
    align-items: center;
    position: relative;
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 225px;
    min-width: 180px;
    // background-color: #fff;
    // background: #ffffff 0% 0% no-repeat padding-box;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(11, 49, 129, 1) 0%,
      rgba(7, 74, 158, 1) 35%,
      rgba(0, 112, 202, 1) 100%
    );
    box-shadow: 0px 3px 6px #00000029;
    // border: 1px solid #0000001f;
    border-radius: 5px;
    // padding: 15px;
    padding-top: 15px;
    width: 33%;
    margin: 20px auto;

    &.best-value {
      box-shadow: 0px 5px 10px #000000a5;
      // border: 3px solid #1e6f9f;
    }
  }

  &__tier-tag {
    font: italic normal bold 14px/22px Roboto;
    letter-spacing: 0.14px;
    width: 200px;
    color: #ffffff;
    background: #041e42;
    text-transform: uppercase;
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
    margin: 0 auto;
    position: relative;
  }

  &__price-container {
    margin-top: 20px;
    margin-bottom: 25px;
    justify-content: center;
  }

  &__price {
    font: italic normal 900 42px/17px Roboto;
    letter-spacing: 0.56px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  &__duration {
    font: italic normal normal 14px/14px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
  }
  &__gym-count-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__gym-count {
    font: normal normal bold 18px Roboto;
    margin: 10px 0 0 0;
    display: flex;

    p.num-of-gyms {
      font-size: 16px;
      margin-right: 5px;
    }

    p.num-des {
      font-size: 16px;
      display: inline;
      font-weight: bold;
    }
  }

  &__studio-class-reward {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font: normal normal bold 14px/18px Roboto;
    color: #333333;
    padding-bottom: 15px;
    p {
      font: italic normal 12px/18px Roboto;
      color: #a9a9a9;
    }
    h4 {
      color: #000;
    }
  }

  &__power {
    .power-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      border: 1px solid #82b265;
      border-radius: 5px;
      padding: 6px 20px;
    }
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6470588235);
  }

  &__elite {
    .elite-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      border: 1px solid #82b265;
      border-radius: 5px;
      padding: 6px 20px;
    }

    .elite-top-text {
      font: normal normal bold 14px/18px Roboto;
    }

    .elite-bottom-text {
      font: normal normal normal 14px/18px Roboto;
      span {
        font: normal normal bold 14px/18px Roboto;
      }
    }
  }

  button {
    border-radius: 15px;
    padding: 5px;
    text-transform: capitalize;
  }

  .best-value-tag {
    position: relative;
    top: -45px;
    margin-bottom: -20px;
    font: italic normal bold 16px Roboto;
    color: #1099d6;
    text-transform: capitalize;
  }
}

.digital-only-text {
  margin-bottom: 30px;
}

.feature-package-select {
  .digital-content-only-container {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(11, 49, 129, 1) 0%,
      rgba(7, 74, 158, 1) 6%,
      rgba(0, 112, 202, 1) 14%,
      transparent 14%
    );

    &__non-payroll {
      background: linear-gradient(
        90deg,
        rgba(11, 49, 129, 1) 0%,
        rgba(7, 74, 158, 1) 10%,
        rgba(0, 112, 202, 1) 24%,
        transparent 24%
      );
    }

    @media (max-width: 850px) {
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(11, 49, 129, 1) 0%,
        rgba(7, 74, 158, 1) 6%,
        rgba(0, 112, 202, 1) 48%,
        transparent 48%
      );
    }
    padding: 25px 15px 15px 25px;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0000001f;
    border-radius: 5px;
    max-width: 795px;
    margin: 0 auto;
    .feature-content__button {
      margin: 1rem auto 0;
      width: 168px;
    }
    &__inverted {
      border-color: white;
    }

    &__tag {
      text-transform: capitalize;
      font: italic normal bold 14px/22px Roboto;
      letter-spacing: 0.14px;
      width: 208px;
      color: #ffffff;
      background: #041e42;
      clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
      margin: 0 auto;
      position: relative;
      top: -36px;
      margin-bottom: -26px;
    }

    &__content-row {
      @media (max-width: 850px) {
        display: flex;
        flex-direction: column;
      }
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__content-column {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-left: 26px;
      font: normal normal normal 14px/18px Roboto;
    }

    &__price-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      @media (max-width: 850px) {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    &__price {
      font: italic normal 900 48px/17px Roboto;
      letter-spacing: 0.56px;
      color: #ffffff;
      margin-bottom: 15px;
      @media (max-width: 850px) {
        margin-top: 10px;
      }
    }

    &__duration {
      font: italic normal normal 14px/14px Roboto;
      color: #ffffff;
      @media (max-width: 850px) {
      }
    }

    &__description {
      @media (max-width: 850px) {
        text-align: center;
      }
      padding-bottom: 7.5px;
    }

    &__description-top-line {
      font: normal normal bold 14px/18px Roboto;
      color: #333333;
    }

    &__description-bottom-line {
      color: #333333;
      @media (max-width: 850px) {
        text-align: center;
      }
    }

    &__includes {
      text-align: left;
      margin-top: 9.5px;
    }

    &__includes-top-text {
      font: italic normal normal 12px/18px Roboto;
    }

    &__includes-bottom-text {
      span {
        font: normal normal bold 14px/18px Roboto;
      }
    }
  }
}

.wellness-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.eligibility-form {
  position: relative;
  &__label {
    position: relative;
    font-weight: bold;
    color: #757575;
  }
  &__sub-label {
    font-size: 12px;
    color: #757575;
  }
  &__row > * {
    padding-left: 0px;
    .add-family-members-button {
      white-space: nowrap;
      width: auto;
      display: inline-block;
      color: var(--primary);
    }
  }
  &__finish_registration_button {
    background-color: var(--primary);
    color: white;
    border-radius: var(--bs-btn-border-radius);
    border-width: var(--bs-btn-border-width);
    border-color: var(--primary);
    padding-bottom: 10px;
    padding-top: 10px;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: var(--hoverButtonColor);
      transition: all 0.3s ease;
      border-color: var(--primary);
      color: var(--primary);
      cursor: pointer;
    }
    &:disabled {
      background-color: var(--modalButton);
      color: var(--contrastTextPrimary);
      cursor: pointer;
    }
  }
  .form-check-input {
    margin: 0px 8px 0px 0px !important;
  }
}

.eligibility-placeholder::placeholder {
  font-size: 12px;
  color: #757575;
}
.add-family-member-form {
  position: relative;
  &__label {
    position: relative;
    font-weight: bold;
    color: #666666;

    &__error {
      position: relative;
      font-weight: bold;
      color: var(--red);
    }
  }
  &__row > * {
    padding-left: 0px;
  }
  .form-check-input {
    margin: 0px 8px 0px 0px !important;
  }
}

.account-template {
  display: grid;
  width: 100%;
  grid-template-columns: fit-content(100px) minmax(0, 1fr);
  grid-template-areas:
    "nav content"
    "footer footer";
  @media (max-width: 992px) {
    grid-template-columns: minmax(0, auto);
    grid-template-areas:
      "content"
      "footer";
  }
  min-height: calc(100vh - 75px);
  &__nav {
    grid-area: nav;
  }
  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem 1.5rem;
    background: #f5f5f5;
    min-height: 640px;
    //fix for primeOnDemand
    //grid-column: 2 / 4;
    //grid-row: 1 / 2;
  }
  &__content-no-padding {
    grid-area: content;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #f5f5f5;
    min-height: 600px;
  }
  &__container {
    max-width: 70%;
  }
  &__footer {
    grid-area: footer;
    background: var(--primary);
    min-height: 200px;
  }
}

.account-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 100px;
  min-height: 100%;
  background: #ffffff;

  &__profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__profile-avatar {
    width: 68px;
    height: 68px;
    box-shadow: 0px 2px 5px #00000029;
    border-radius: 50%;
    background: #f5f5f5;
    text-align: center;
    text-transform: uppercase;
    line-height: 68px;
    color: var(--primary);
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  }
  &__profile-title {
    margin-top: 6px;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
  }
  &__view-profile-link {
    margin-top: 5px;
    font-size: 11px;
    text-decoration: none;
    text-align: center;
    :hover {
      color: #008800;
    }
  }
  &__nav-container {
    margin-top: 40px;
    width: 100%;
    height: 100px;
  }
  &__link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.account-nav {
  text-align: center;
  align-items: center;
  text-wrap: wrap;
  max-width: 100px;
  ul {
    margin: 0px;
    padding: 0 20px;
    text-align: center;
  }
  li {
    margin-bottom: 18px;
    line-height: 14px;
  }
  &__active {
    color: var(--text);
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
    color: var(--clientPrimary) !important;
    background-color: #f5f5f5 !important;
    width: 100%;
    transition-timing-function: ease-in;
  }
  a {
    color: var(--text);
    font-size: 12px;
    text-decoration: none;
    &.log-out {
      margin-top: 32px;
      color: #dc3545;
      &:hover {
        color: #dc3545;
      }
    }
  }
  a:hover {
    text-decoration: none;
    color: var(--text);
  }
  .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.account-card {
  box-shadow: 0px 2px 8px #00000040;
  &__body {
    position: relative;
  }
  &__title {
    margin-bottom: 1.5rem;
  }
}

.account-banner {
  border-radius: 0.25rem;
  &__enroll {
    margin-bottom: 2rem;
    background: var(--primary);
    height: 116px;
  }
  &__whl {
    margin-top: 2rem;
    height: 180px;
    background: var(--primary);
  }
}

.account-overview {
  position: relative;
  min-height: 400px;
  &__containers {
    padding: 0px 16px;
    display: flex;
    align-items: stretch;
    margin: 0 -8px;
  }
  &__card-container {
    margin: 0 8px;
  }
  &__plan-container {
    margin: 0 8px;
  }
  &__plan {
    min-height: 200px;
  }
  &__activity-container {
    height: 200px;
    margin-top: 1rem;
  }
}

.account-help {
  position: relative;
  min-height: 400px;
  &__containers {
    padding: 0px 16px;
    display: flex;
    align-items: stretch;
    margin: 0 -8px;
  }
  &__card-container {
    margin: 0 8px;
    p {
      font-size: 16px;
    }
  }
  &__assistance {
    background-color: #c7c4c452;
    // min-height: 200px;
  }
  &__information {
    background-color: #dcedf6;
  }
}

.membership-card {
  @media (max-width: 340px) {
    max-width: 100%;
  }
  @media (min-width: 341px) {
    max-width: 340px;
  }
  width: 100%;
  img {
    width: 79%;
  }
  &__card {
    // height: 100px;
    width: 134px;
    //background: mediumspringgreen;
  }
  &__button-container {
    margin-top: 8px;
    // width: 66%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    // margin: 4px -2px;
    button {
      border-radius: 20px;
      width: 45%;
    }
  }
  &__button {
    padding: 8px 16px;
    border-radius: 20px;
    background: none;
    border: 1px solid var(--primary);
    color: var(--primary);
    text-align: center;
    flex: 1;
    margin: 0 2px;
    margin-right: 5px;
  }
}

.error-message-text {
  color: red !important;
  text-align: center;
}

.renroll-text {
  color: white;
}

.reenroll-subtext {
  font-size: 0.8rem;
  color: white;
}

.reenroll-banner {
  display: flex;
  // flex: 1;
  width: 100%;
  flex-direction: row;
  // margin-bottom: 1rem;
  // max-width: 845px;
  // max-height: 210px;
  @media (max-width: 798px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    max-width: 415px;
    max-height: none;
  }
  a {
    text-decoration: none;
  }
  button {
    border-radius: 20px;
    width: 75%;
  }
  &__header {
    width: 30%;
    @media (max-width: 798px) {
      width: 100%;
      max-width: 415px;
      max-height: 25%;
      img {
        display: none;
        max-height: none;
      }
    }
  }
  &__body {
    padding: 1rem;
    width: 40%;
    align-self: center;
    @media (max-width: 798px) {
      width: 100%;
      max-width: 415px;
      p {
        // align-self: center;
        text-align: center;
      }
    }
  }
  &__footer {
    padding: 0 5px 1rem 0;
    width: 27%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 798px) {
      flex: 1;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
      width: 100%;
      max-width: 415px;
      button {
        width: 100%;
      }
      // max-height: 25%;
      // padding: 10px 10px 1rem 0;
    }
    @media (min-width: 798px) {
      width: 35%;
      margin-left: 40px;
    }
    @media (min-width: 900px) {
      width: 27%;
      margin-left: 40px;
    }
    @media (min-width: 1200px) {
      width: 31%;
      margin-left: 50px;
    }
    @media (min-width: 1400px) {
      width: 27%;
      margin-left: 50px;
    }
    @media (min-width: 1500px) {
      width: 24%;
      margin-left: 80px;
    }
  }
  &__footer__special {
    padding: 0 5px 1rem 0;
    width: 27%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 798px) {
      flex: 1;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
      width: 100%;
      max-width: 415px;
      button {
        width: 100%;
      }
      // max-height: 25%;
      // padding: 10px 10px 1rem 0;
    }
    @media (min-width: 798px) {
      width: 35%;
      margin-left: 40px;
    }
    @media (min-width: 900px) {
      width: 27%;
      margin-left: 40px;
    }
    @media (min-width: 1200px) {
      width: 31%;
      margin-left: 50px;
    }
    @media (min-width: 1400px) {
      width: 27%;
      margin-left: 50px;
    }
    @media (min-width: 1500px) {
      width: 24%;
      margin-left: 80px;
    }
  }
}

.upgrade-banner {
  display: flex;
  // flex: 1;
  flex-direction: row;
  border: none;
  box-shadow: 0px 2px 4px #00000029;
  margin-bottom: 1rem;
  margin-right: 15px;
  // margin-bottom: 1rem;
  // max-width: 845px;
  // max-height: 210px;
  @media (max-width: 798px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    max-width: 415px;
    max-height: none;
  }
  a {
    text-decoration: none;
  }
  button {
    border-radius: 3px;
    width: 75%;
  }
  &__button {
    box-shadow: none;
  }
  &__header {
    width: 30%;
    @media (max-width: 798px) {
      width: 100%;
      max-width: 415px;
      max-height: 25%;
      img {
        display: none;
        max-height: none;
      }
    }
  }
  &__body {
    padding: 1rem;
    width: 40%;
    align-self: center;
    @media (max-width: 798px) {
      width: 100%;
      max-width: 415px;
      p {
        // align-self: center;
        text-align: center;
      }
    }
  }
  &__footer {
    padding: 0 5px 1rem 0;
    width: 27%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 798px) {
      flex: 1;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
      width: 100%;
      max-width: 415px;
      button {
        width: 100%;
      }
      // max-height: 25%;
      // padding: 10px 10px 1rem 0;
    }
    @media (min-width: 798px) {
      width: 35%;
      margin-left: 40px;
    }
    @media (min-width: 900px) {
      width: 27%;
      margin-left: 40px;
    }
    @media (min-width: 1200px) {
      width: 31%;
      margin-left: 50px;
    }
    @media (min-width: 1400px) {
      width: 27%;
      margin-left: 50px;
    }
    @media (min-width: 1500px) {
      width: 24%;
      margin-left: 80px;
    }
  }
  &__footer__special {
    padding: 0 5px 1rem 0;
    width: 27%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 798px) {
      flex: 1;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
      width: 100%;
      max-width: 415px;
      button {
        width: 100%;
      }
      // max-height: 25%;
      // padding: 10px 10px 1rem 0;
    }
    @media (min-width: 798px) {
      width: 35%;
      margin-left: 40px;
    }
    @media (min-width: 900px) {
      width: 27%;
      margin-left: 40px;
    }
    @media (min-width: 1200px) {
      width: 31%;
      margin-left: 50px;
    }
    @media (min-width: 1400px) {
      width: 27%;
      margin-left: 50px;
    }
    @media (min-width: 1500px) {
      width: 24%;
      margin-left: 80px;
    }
  }
  &__img {
    border-radius: 5px 0px 0px 5px;
  }
}

.reenroll-btn {
  color: var(--primary) !important;
  border-radius: 5px;
  background-color: white !important;
  align-items: left;
  font-size: 14px;
  padding: 12px;
  position: absolute;
  top: 30px;
  right: 50px;
}

.reenroll-container {
  padding-top: 20px;
}

.footer-enroll {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  column-gap: 1rem;
}

.footer-enroll-btns {
  white-space: nowrap;
  width: 100%;
}

.tile-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 798px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
  &__card {
    flex-grow: 1;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 48%;
    min-width: 350px;
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    @media (max-width: 798px) {
      flex: 1;
      width: 100%;
    }
    a {
      text-decoration: none;
    }
  }
  &__card:last-child() {
    max-width: 49%;
    @media (max-width: 798px) {
      max-width: 100%;
    }
  }

  &__card-whl {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: #0d2674;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    width: 48%;
    min-width: 350px;
    padding: 30px 10px 10px;
    &__whlHeader {
      display: flex;
      width: 80%;
      justify-content: center;
      flex-direction: row;
      @media (max-width: 798px) {
        height: 40%;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    &__whlBody {
      display: flex;
      width: 80%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 798px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    &__whlFooter {
      display: flex;
      flex-wrap: nowrap;
      width: 60%;
      justify-content: center;
      flex-direction: row;
      button {
        border-radius: 20px;
        width: 100%;
      }
      @media (max-width: 798px) {
        width: 100%;
      }
    }
    @media (max-width: 798px) {
      flex: 1;
      width: 100%;
    }
    a {
      text-decoration: none;
    }
    p {
      font-family: "Roboto";
      font-size: 14px;
    }
  }
  &__card-whl:nth-child(odd) {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    background-color: #0d2674;
    justify-content: space-between;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    width: 48%;
    min-width: 350px;
    padding: 10px 10px 10px;
    button {
      border-radius: 20px;
      max-height: 35px;
      align-self: center;
      justify-self: center;
    }
    @media (max-width: 798px) {
      flex: 1;
      width: 100%;
      flex-direction: column;
    }
  }
  &__card-digital:not(:last-child) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    width: 48%;
    min-width: 350px;
    padding: 10px 10px 10px;
    &.les-mills {
      width: 65%;
      margin: 50px;
    }
    &.burnalong {
      width: 65%;
    }
    @media (max-width: 798px) {
      flex: 1;
      width: 100%;
    }
    &.yoga {
      width: 100%;
      margin-bottom: 0px;
    }
    button {
      max-height: 35px;
      align-self: center;
    }
    a {
      text-decoration: none;
    }
    p {
      font-family: "Roboto";
      font-size: 14px;
    }
  }
  &__card-digital:last-child {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    width: 48%;
    max-width: 49%;
    padding: 10px 10px 10px;
    @media (max-width: 798px) {
      max-width: 100%;
      flex: 1;
    }
    &__les-mills {
      margin: 50px;
      padding: 20px;
      height: 5px;
    }
    &__burnalong {
      width: 20%;
      height: 5px;
    }
    &__yoga {
      max-height: 60px;
      height: 5px;
      margin-bottom: 0px;
    }
    button {
      max-height: 35px;
      align-self: center;
    }
    a {
      text-decoration: none;
    }
    p {
      font-family: "Roboto";
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    span {
      font-size: 0.875rem;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    height: 90%;
  }
  &__footer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    a {
      text-decoration: none;
    }
    button {
      border-radius: 20px;
      width: 75%;
    }
  }
  &__footer-plan {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    button {
      border-radius: 20px;
      width: 75%;
    }
  }
}

.package-list {
  -webkit-padding-start: 0;
  &__package {
    font-size: 0.875rem;
    font-weight: bold;
  }
  &__package::before {
    content: "\2713";
    color: #82b265;
    display: inline;
    font-weight: 800;
    margin-right: 5px;
  }
}
.package-list-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-padding-start: 0;
  &__package {
    font-size: 13px;
    margin-right: 5px;
  }
  &__package::before {
    content: "\2713";
    color: var(--myPlanCheckmark);
    display: inline;
    font-weight: 800;
    margin-right: 3px;
  }
}
.account-footer {
  color: var(--contrastTextPrimary);
  h4 {
    color: var(--contrastTextPrimary);
    margin-bottom: 3rem;
  }
  &__bullet {
    color: var(--contrastTextPrimary);
    font-weight: bold;
    font-size: 0.8rem;
  }
  &__bullet::before {
    content: "\2022";
    margin-right: 8px;
  }
  &__description {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
}
.flyin-bullet {
  &__bullet {
    color: black;
    font-size: 14;
    margin-top: -23px;
    margin-right: 5px;
    margin-bottom: -20px;
    margin-left: -30px;
  }
  &__bullet::before {
    content: "\25CF";
    font-size: 25px;
    margin-top: 10px;
  }
}

.full-height {
  height: calc(100vh - 75px);
}

.package-selection-container {
  @media (min-width: 1279px) {
    max-width: 66%;
  }
  .header-component {
    margin-top: 16px;
    margin-bottom: 30px;

    @media (max-width: 1170px) {
      margin-bottom: 10px;
    }
  }
}

.mutual-package-selection {
  @media (min-width: 1279px) {
    max-width: 55%;
  }
}

.package-card-container {
  @media (max-width: 1170px) {
    margin-top: 15px !important;
  }

  .package-selected-button {
    white-space: nowrap;
    margin: 1rem auto 0;
    background: white;
    border-radius: 5px;
  }
  .package-selected-button.active {
    background-color: #449d44;
  }
  .Fitness.Pass.Power {
    @media (max-width: 1170px) {
      display: flex;
      justify-content: center;
    }
    label[for="Fitness Pass Power"] {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6470588235);
      .card {
        border: 1px;
      }
    }
  }
  .Fitness.Pass.Core {
    @media (max-width: 1170px) {
      display: flex;
      justify-content: center;
    }
  }
  .Fitness.Pass.Ultra {
    @media (max-width: 1170px) {
      display: flex;
      justify-content: center;
    }
  }
  .Fitness.Pass.Digital {
    @media (max-width: 1170px) {
      display: flex;
      justify-content: center;
    }
    label[for="Fitness Pass Digital"] {
      padding-left: 0;
    }
  }
  .digital {
    padding-right: 10px;
    padding-left: 0px;
    border-radius: 5px;
  }
  .card {
    width: 220px;
    @media (max-width: 1170px) {
      display: flex;
      justify-content: center;
    }
  }
  .body {
    background: $light-gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .luxury-gym-info {
    padding-right: 70px;
    padding-top: 5px;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
  }
  .best-value-tag {
    position: relative;
    top: -190px;
    margin-bottom: -20px;
    font: italic normal bold 14px Roboto;
    text-transform: capitalize;
    color: var(--primary);
  }

  .card.digital {
    margin-top: 28px;
  }
  .form-group {
    position: absolute;
    .form-check {
      padding-left: 0;
    }
  }
  .card-title {
    text-align: center;
    text-transform: uppercase;
    font: italic normal bold 14px/22px Roboto;
    letter-spacing: 0.14px;
    width: 200px;
    color: #ffffff;
    background: #21445c;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
    margin: 0 auto;
    position: relative;
  }
  .price {
    text-align: center;
    font: italic normal 900 44px/19px Roboto;
    letter-spacing: 0.44px;
    color: #222222;
    margin-top: 30px;
  }
  .per-month {
    text-align: center;
    margin-top: 8px;
    font: italic normal normal 12px/18px Roboto;
    color: #222222;
  }
  .num-of-gyms {
    text-align: center;
    font: normal normal 14px/18px Roboto;
    font-weight: bold;
  }
  .elite-gym-choice {
    font: normal normal 14px/18px Roboto;
    text-align: center;
    border: 1px solid #57cdbf;
    border-radius: 5px;
    padding: 5px;
  }
  .card-footer {
    background: rgb(255, 255, 255);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .footer-top-line {
    text-align: center;
    font: italic normal normal 12px/18px Roboto;
    color: #333333;
    margin-bottom: 12px;
  }
  .feature-title {
    text-align: center;
    font: normal normal bold 14px/18px Roboto;
    color: #333333;
  }
  .feature-body {
    text-align: center;
    font: normal normal normal 13px/18px Roboto;
    color: #333333;
  }
  .digital-content-only-container {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0000001f;
    border-right: 1px solid #ffffff;
    border-radius: 5px;
    position: relative;
    width: 600px;
    margin: 0 auto;

    &__weekly {
      background: linear-gradient(
        90deg,
        rgba(11, 49, 129, 1) 0%,
        rgba(7, 74, 158, 1) 10%,
        rgba(0, 112, 202, 1) 23%,
        transparent 23%
      );

      @media (max-width: 87.44em) {
        background: linear-gradient(
          90deg,
          rgba(11, 49, 129, 1) 0%,
          rgba(7, 74, 158, 1) 15%,
          rgba(0, 112, 202, 1) 28%,
          transparent 28%
        );
      }

      @media (max-width: 74.94em) {
        background: linear-gradient(
          90deg,
          rgba(11, 49, 129, 1) 0%,
          rgba(7, 74, 158, 1) 15%,
          rgba(0, 112, 202, 1) 35%,
          transparent 35%
        );
      }

      @media (max-width: 61.94em) {
        background: linear-gradient(
          90deg,
          rgba(11, 49, 129, 1) 0%,
          rgba(7, 74, 158, 1) 15%,
          rgba(0, 112, 202, 1) 31%,
          transparent 31%
        );
      }

      @media (max-width: 47.94em) {
        background: linear-gradient(
          90deg,
          rgba(11, 49, 129, 1) 0%,
          rgba(7, 74, 158, 1) 15%,
          rgba(0, 112, 202, 1) 45%,
          transparent 45%
        );
      }
    }

    &__button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: var(--primary) !important;
      color: var(--contrastTextPrimary) !important;
      padding: 16px;
      font-size: 0.9rem;
      max-width: 200px;
      white-space: nowrap;
      &--contrast {
        background: #ffffff !important;
        color: var(--primary) !important;
      }
      &--inverted {
        // background: #ffffff !important;
        border-color: #ffffff !important;
      }
      &--large {
        font-size: 1.1rem;
        padding: 16px 24px;
        max-width: 250px;
      }
      &:hover {
        transition: filter 0.3s;
        filter: brightness(85%);
      }
    }

    &__tag {
      text-transform: capitalize;
      text-align: center;
      font: italic normal bold 14px/22px Roboto;
      letter-spacing: 0.14px;
      width: 208px;
      color: #ffffff;
      background: #041e42;
      clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
      margin: 0 auto !important;
      position: absolute;
      top: -14px;
      left: 0;
      right: 0;
    }

    &__content-row {
      display: flex;
    }

    &__price-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $light-gray;
      color: #222222;
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 23px;
    }

    &__price {
      font: italic normal 900 44px Roboto;
      letter-spacing: 0.56px;
    }

    &__interval {
      font: italic normal normal 14px Roboto;
      margin-top: -5px;
    }

    &__description-container {
      display: flex;
      flex-direction: column;
      padding-top: 25px;
      padding-left: 20px;
      font: normal normal normal 14px/18px Roboto;

      .divider {
        margin: 8px 0px;
      }
    }

    &__description {
      &__payroll {
        padding-left: 18px;
        margin-bottom: 20px;
      }
      text-align: left;
      padding-bottom: 7.5px;
    }

    &__description-top-line {
      font: normal normal bold 14px/18px Roboto;
      color: #333333;
    }

    &__description-middle-line {
      text-align: left;
      color: #333333;
    }

    &__description-bottom-line {
      font-size: 12px;
    }

    &__includes {
      text-align: left;
      margin-top: 9.5px;
    }

    &__includes-top-text {
      font: italic normal normal 12px/18px Roboto;
    }

    &__includes-bottom-text {
      span {
        font: normal normal bold 14px/18px Roboto;
      }
    }
  }
}

// summary-container
.summary-container {
  &__body {
    padding: 1rem 2rem;
  }
  &__error {
    text-align: center;
  }
  &__loading {
    text-align: center;
    h4 {
      margin-bottom: 1rem;
    }
    margin-bottom: 1rem;
  }
  &_heading {
    font: normal normal bold 12px/14px Roboto;
    color: #707070;
    text-transform: uppercase;
    margin-left: -1rem;
  }
  &__footer {
    font-size: 0.875rem;
    font-style: italic;
    padding: 1rem;
  }
  &_selection-total,
  &_discount-total {
    font: normal normal bold 14px/24px Roboto;
    color: #0d2674;
  }
  &_selection-price {
    font: normal normal bold 14px/24px Roboto;
    color: #333333;
    float: right;
  }
  &_enrollment-price {
    float: right;
  }
  .package-total-accordion,
  .discount-accordion {
    .card-header {
      background-color: unset;
      border-bottom: unset;
      padding: 0;
    }
    ul {
      font: normal normal normal 14px/24px Roboto;
      padding-left: unset;
    }
  }
  .enrollment-container,
  .discount-accordion {
    font: normal normal normal 14px/24px Roboto;
    &.card-body {
      padding: 0;
    }
  }
  &__promo-item {
    border: 1px solid #0d2674;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    button {
      border: none;
      background: none;
    }
  }
  .check-circle-fill {
    fill: #67c167;
    width: 16px;
    margin-left: 0.25rem;
  }
  .trash-fill {
    fill: #0d2674;
    width: 16px;
    margin-right: 0.25rem;
  }
  .promo-code-label {
    font: normal normal bold 12px/14px Roboto;
    color: #707070;
    text-transform: uppercase;
  }
  .promo-input-container {
    margin-bottom: 0.5rem;
  }
  .payment-start-date {
    font: italic normal normal 14px/16px Roboto;
    color: #333333;
  }
  .payment-total {
    margin-bottom: 0;
  }
  .price-amount {
    text-align: right;
  }
  &__total-due-now {
    font: normal normal bold 14px/24px Roboto;
  }
  .package-selections-total-row,
  .discounts-total-row {
    cursor: pointer;
  }
  .monthly-total-row {
    font: normal normal bold 14px/16px Roboto;
  }
  &_promo-code-error {
    color: #dc3545;
    font-size: 0.875em;
  }
}

// end summary-container

.terms-container {
  h3 {
    margin-top: 30px;
    font: normal normal bold 25px/24px Roboto;
  }
  label {
    font: normal normal normal 14px/22px Roboto;
    max-width: 75%;
  }
  .form-check {
    padding-left: 0;
  }
}

.form-requirements {
  color: #757575;
  font: normal normal normal 12px/14px Roboto;

  &.dependent-email {
    padding-top: 5px;
    width: 60%;
  }

  &.invalid {
    color: #f0513c;
  }
  &.valid {
    color: #68c167;
  }
}

.nav-btn-enroll {
  width: 222px;
}
.nav-btn-enroll-change {
  width: 222px;
  height: 35px;
}

.discount-list {
  font-size: 12px;
  padding: 0.5rem 0 !important;
}

.center-loading {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: 100%;
  margin-top: 20%;
}
.zuora-loading {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: 100%;
}
.zuora-center-error {
  width: 100%;
  text-align: center;
  padding: 16px;
}

.account-template__content {
  .custom-control.custom-switch {
    height: 100%;
    .activity-log-container {
      height: 100%;
      .card {
        height: 100%;
      }
    }
  }
  .page-info-container {
    .pagination {
      margin-bottom: 0;
    }
  }
}

.activity-log-container {
  &_header {
    border-bottom: 1px solid #d9d9d9;
  }
  .date-range-text,
  .from-text,
  .to-text {
    font-weight: bold;
  }
  .date-picker-container {
    margin-bottom: 16px;
  }
  .react-date-picker__wrapper {
    border-radius: 3px;
    border-color: #dee2e6;
    font-weight: lighter;
  }
  .react-date-picker__inputGroup__input {
    font-weight: lighter;
  }
  .activity-log__type-selection {
    border-color: #dee2e6;
    cursor: pointer;
  }
  .vr {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

// Set border-radius on the top-left and bottom-left of the first table data on the table row
td:first-child {
  border-radius: 20px 0 0 20px;
}

// Set border-radius on the top-right and bottom-right of the last table data on the table row
td:last-child {
  border-radius: 0 20px 20px 0;
}

.nav-bar-top {
  &__image {
    max-height: 36px;
    margin-right: calc(22px - 1rem);
  }
  &__text {
    margin-right: 20px;
  }
}

.faq-page {
  a{
    color: #0071ce;
    &:hover {
      color: #0071ce;
    }
  }
  &__header {
    margin: 24px auto;
    margin-bottom: 36px;
  }
  &__container {
    max-width: 1000px;
  }
  &__section {
    margin-bottom: 1rem;
  }
  &__section:not(:first-child) {
    margin-top: 3rem;
  }
  &__bottom {
    margin-top: 4rem;
  }
}

.faq-section {
  div {
    p {
      display: inline;
    }
    ul:first-child {
      margin-top: 0px;
    }
    ul li::before {
      content: "\2022";
      display: inline-block;
      margin-left: -1em;
      width: 1em;
      margin-top: 8px;
    }
  }
  &__question {
    color: var(--primary);
    background: white;
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__accordion {
    margin-bottom: 1em;
  }
  &__body {
    background: #f5f5f5;
    margin-top: 1px;
    cursor: default;
  }
  &__card {
    border-color: #e0e0e0;
  }
}
/*override default styling*/
.accordion > .card > .card-header {
  margin-bottom: -1px;
}

.legal-footer {
  background: var(--primary);
  min-height: 200px;
  padding: 4rem 8rem;
  width: 100%;
}

.fitness-program-agreement {
  max-width: 900px;
  padding-bottom: 100px;
  h1 {
    margin: 50px 0;
  }
  h2 {
    margin: 60px 0 40px 0;
    color: #aaa;
    font-size: 18px;
    font-weight: 600;
    + ul {
      color: #aaa;
      display: flex;
      gap: 4%;
      flex-wrap: wrap;
      li {
        flex: 48%;
        max-width: 48%;
        margin-bottom: 20px;
      }
    }
  }
  h3 {
    margin: 60px 0 30px 0;
    color: #aaa;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    + p {
      margin-bottom: 20px;
      color: #aaa;
      text-align: center;
      + p {
        color: #aaa;
        text-align: center;
      }
    }
  }
  p {
    margin-bottom: 20px;
  }
  a:link {
    color: rgb(0, 136, 255);
  }
  .terms__table {
    max-width: 100%;
  }
  ul li {
    list-style-type: disc;
    b {
      display: block;
    }
  }
  ol li {
    display: list-item;
    list-style-type: auto;
    list-style-position: inside;
    margin-left: 3em;
    margin-bottom: 20px;
    p {
      display: inline;
    }
  }

  &__sectionHeader {
    color: var(--primary);
    margin: 10px 0px;
  }
  &__table {
    border: 1px solid black;
    max-width: 600px;
    border-collapse: collapse;
    //for the table
    div > tbody,
    td {
      border: 1px solid black;
      padding: 24px;
      text-align: left;
      vertical-align: center;
      p {
        display: inline;
      }
      ul li::before {
        content: "\2022" !important;
        display: inline-block;
        margin-left: -1em;
        width: 1em;
      }
      ul li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

.terms {
  a:link {
    color: rgb(0, 136, 255);
  }
  ul li {
    list-style: disc;
    p {
      display: inline;
    }
    //second list ie. 1. 2. 3. 4. etc.
    ol li {
      background-color: #27af0c;
      list-style-type: none;
      margin-left: -1em;
    }
  }
  //top list
  ol li {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    // margin-bottom: 12px;
    margin-left: 3em;
    p {
      display: inline;
    }
    //second list ie. 1. 2. 3. 4. etc.
    ol li {
      // background-color: #0c7faf;
      list-style-type: none;
      margin-left: -1em;

      //third list
      ol li::before {
        content: "\2022";
        display: inline-block;
        margin-right: 4px;
        margin-left: -1em;
        width: 1em;
      }
      ol li {
        margin-top: 8px !important;
        margin: 0px;
        //unknownthing after
        ol li {
          margin: 0px !important;
        }
        ol li:first-child {
          margin-top: 12px !important;
        }
      }
    }
    //nested ol in ol
    ol li::before {
      content: "";
      display: inline-block;
    }
  }

  &__sectionHeader {
    color: var(--primary);
    margin: 10px 0px;
  }
  &__table {
    border: 1px solid black;
    max-width: 600px;
    border-collapse: collapse;
    //for the table
    div > tbody,
    td {
      border: 1px solid black;
      padding: 24px;
      text-align: left;
      vertical-align: center;
      p {
        display: inline;
      }
      ul li::before {
        content: "\2022" !important;
        display: inline-block;
        margin-left: -1em;
        width: 1em;
      }
      ul li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
.privacy {
  &__table {
    border: 1px solid black;
    max-width: 1000px;
    border-collapse: collapse;
    //for the table
    div > tbody,
    td {
      border: 1px solid black;
      padding: 24px;
      text-align: left;
      vertical-align: text-top;
      p {
        display: inline;
      }
      ul li::before {
        content: "\2022" !important;
        display: inline-block;
        margin-left: -1em;
        width: 1em;
      }
      ul li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

.agreement {
  ul li {
    p {
      display: inline;
    }
  }
  //top list
  ol li {
    display: list-item;
    list-style-type: decimal;
    list-style-position: inside;
    margin-bottom: 12px;
    p {
      display: inline;
    }
    //second list ie. 1. 2. 3. 4. etc.
    ol li {
      list-style-type: none;
      margin-left: -1em;

      ol li {
        list-style-type: lower-alpha;
        margin-top: 8px !important;
        margin: 0px;
        //unknownthing after
        ol li {
          margin: 0px !important;
        }
        ol li:first-child {
          margin-top: 12px !important;
        }
      }
    }
    //nested ol in ol
    ol li::before {
      content: "";
      display: inline-block;
    }
  }

  &__sectionHeader {
    color: var(--primary);
    margin: 10px 0px;
  }
  &__table {
    border: 1px solid black;
    max-width: 600px;
    border-collapse: collapse;
    //for the table
    div > tbody,
    td {
      border: 1px solid black;
      padding: 24px;
      text-align: left;
      vertical-align: center;
      p {
        display: inline;
      }
      ul li::before {
        content: "\2022" !important;
        display: inline-block;
        margin-left: -1em;
        width: 1em;
      }
      ul li:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

.custom-width {
  width: 105%;
}

.smaller-font {
  font-size: 12px;
}

.react-date-picker__inputGroup {
  min-width: fit-content !important;
}

.activity-log {
  &__table-header {
    border-bottom: none;
  }
  &__table-body,
  &__table-row,
  &__table-data {
    border-style: none;
  }

  &__type-selection {
    color: var(--dark);
    max-width: 150px;
    font-weight: bold;
    text-align: center;
    border-radius: 30px;
    padding: 5px 10px;
  }
  @media (min-width: 992px) {
    &__activity-item:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  &__apply-filter-btn {
    max-width: 150px;
    border-radius: 30px;
    padding: 5px 10px;
    color: #0078bf;
    border-color: #0078bf;
  }
  @media (min-width: 992px) {
    &__activity-item:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  &__clear-filter-btn {
    max-width: 150px;
    border-radius: 30px;
    padding: 5px 10px;
    color: #0078bf;
    border-color: #0078bf;
  }
  @media (min-width: 992px) {
    &__activity-item:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  &__activity-item-tile:nth-child(even) {
    background-color: #f5f5f5;
    border-radius: 30px;
  }
}

.activity-pagination-controls {
  cursor: pointer;
}

.widget {
  display: flex;
  @media (max-width: 798px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }

  &__card {
    flex-grow: 1;
    box-shadow: 0px 3px 6px #00000029;
    // width: 140px;
    width: 48%;
    border-radius: 5px 5px;
    margin: 0 15px 15px 0;
    position: relative;
    //max-height: 400px;
    // max-width: 415px;
    position: relative;
    background: #ffffff;

    &__max-length {
      width: 100%;
    }
  }
  &__card:last-child() {
    max-width: 49%;
    @media (max-width: 798px) {
      max-width: 100%;
    }
  }
  &__position {
    position: relative;
  }
  &__img {
    width: 100%;
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    min-height: 245px;
  }
  &__overlay-text {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0.625rem 1.25rem;
    color: #fff;
    font-weight: 700;
    background: transparent
      linear-gradient(180deg, #00000067 0%, #19191900 100%) 0% 0% no-repeat
      padding-box;
    font-size: 16px;
    width: 100%;
    height: 245px;
  }
  &__content {
    margin: 0px 0 60px 0;
    background: #ffffff;
  }
  &__icon {
    display: inline-block;
    margin-left: 7px;
  }
  &__btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0d2674;
    border-radius: 17px;
    color: #0d2674;
    font-size: 14px;
    font-weight: bold;
  }
  &__link {
    position: absolute;
    background: #ffffff;
    width: 100%;
    bottom: 0;
    margin-bottom: 10px;
  }
  &__link-button {
    border-radius: 17px;
    padding: 5px 20px;
    width: 365px;
    margin: 10px auto !important;
    display: block;
    position: relative;
    background-color: #fff;
    color: #0d2674;

    @media (max-width: 460px) {
      width: 265px;
    }
    @media (max-width: 360px) {
      width: auto;
    }
  }
  &__link-icon {
    position: absolute;
    //right: 5px;
    top: 5px;
    width: 8px !important;
    margin-left: 3px;
  }
  sup {
    font-size: 7px;
    top: -10px;
  }
  p {
    font-size: 14px;
  }
  a {
    text-decoration: none !important;
  }

  .burnalong-card {
    width: 50%;
    border-radius: 5px !important;
    .img-fluid {
      height: 228px;
      object-fit: cover;
      object-position: 0px 50%;
    }
    .burn-along-paragraph {
      margin: 0 0 5px 0;
    }
    .burnalong-button {
      padding: 6px 20px;
      color: var(--clientPrimary);
      border-color: var(--clientPrimary);
      display: block;
      margin: 0 auto;
      font-size: 16px;
      font-weight: "bold";
      border-radius: 3px;

      @media (max-width: 1024px) {
        width: 100%;
      }

    }
    .burnlong-logo {
      margin-bottom: 5px;
    }
    [class^="card_widget__content__"] {
      border-radius: 5px;
    }
    [class^="card_widget__overlay-text__"] {
      border-radius: 5px;
    }
    [class^="card_widget__icon__"] {
     margin-left: 0px;
     font-size: 20px;
    }
    @media (max-width: 1170px) {
      width: 100%;
    }
  }
}
.myPlan-tile {
  a {
    color: #000000;
    &:hover {
      color: #000000;
    }
  }
}

.app-navbar {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(239, 239, 239, 1) 200%
  );
  &-account {
    background: linear-gradient(
      90deg,
      rgba(0, 31, 65, 1) 0%,
      rgba(0, 31, 65, 1) 100%
    );
    & .navbar-nav .nav-link {
      color: #ffffff;
    }
  }
  &-hcsc-account {
    color: var(--clientPrimary);
    & .navbar-nav .nav-link {
      color: var(--clientPrimary);
    }
  }
  .navbar-brand {
    margin-right: 0;
  }
  #basic-navbar-nav-2 {
    justify-content: end;
  }
  @media (min-width: 992px) {
    &.second {
      top: 75px;
    }
  }
  @media (max-width: 992px) {
    min-height: unset;
    .navbar-brand {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .navbar-nav {
      .my-account-header {
        color: var(--clientPrimary);
        margin: 0 0 -5px 0;
        font: normal normal bold 14px/19px Roboto;
      }
      .nav-link {
        font: normal normal normal 14px/19px Roboto;
        &.log-out {
          margin-top: 12px;
          margin-bottom: 75px;
        }
      }
    }
  }
  &__my-account-header {
    font-weight: 700;
  }
  &__toggle-text {
    margin-left: 5px;
    font: normal normal bold 16px/20px Roboto;
    text-transform: capitalize;
  }
  .navbar-toggler {
    border: none;
    cursor: pointer;
    .toggle-close {
      display: block;
    }
    .toggle-open {
      display: none;
    }
    &.collapsed {
      .toggle-open {
        display: block;
      }
      .toggle-close {
        display: none;
      }
    }
  }
  .nav-link {
    &.log-out {
      margin-top: 32px;
      color: #dc3545;
      &:hover {
        color: #dc3545;
      }
    }
    display: block;
    @media (min-width: 992px) {
      &.isHiddenOnLarge {
        display: none !important;
      }
    }
  }

  .btn {
    @media (min-width: 992px) {
      &.isHiddenOnLarge {
        display: none !important;
      }
    }

    @media (min-width: 992px) {
      &.isEnrollOnLarge {
        color: white;
        border-radius: 5px;
        background-color: var(--primary);
        border: 1px solid;
        // padding: 0.7rem;
      }
    }

    @media (max-width: 990px) {
      &.isHiddenOnSmall {
        // font-size: 12px !important;
        min-width: 0px;
        text-align: center;
        color: var--(--primary);
        text-decoration: none;
        font: normal normal normal 14px/19px Roboto;
        width: min-content;
        padding: 0px;
        margin: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
        white-space: nowrap;
        &:focus {
          outline: none !important;
          box-shadow: none;
          text-decoration: underline;
        }
      }
    }
  }

  .toast-error {
    background-color: #f77171;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .error-page {
    margin-top: 4em;
    text-align: center;
  }
}

.membership-row {
  flex-wrap: wrap-reverse;
}

.upcoming-payment-display {
  display: flex;
  font: normal normal normal 18px/21px Roboto;
  margin-bottom: 16px;
}

.vp-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-bottom: 12px;
}

.vp {
  padding: 24px;
  .card-title {
    font-size: 18px;
    color: var(--primary);
  }
  .required-field-marker {
    color: var(--danger);
    margin-left: 2px;
  }

  .card {
    box-shadow: 0px 2px 8px #dddddd;
    border: 1px solid #0000001f;
    border-radius: 5px;
  }
  &__personal-info {
    border-bottom: 1px solid #0000001f;
  }
}
.package-dropdown {
  @media (max-width: 1170px) {
    width: 95%;
    margin-top: -5px;
  }
}

.manage-plans {
  .custom-card-content {
    width: 100%;
  }
  &__cancel {
    @media (max-width: 1170px) {
      margin-left: 9px !important;
      display: flex;
      justify-content: center;
    }
  }
  &__member-name {
    @media (max-width: 1170px) {
      margin-bottom: 10px;
    }
  }
  &.row {
    margin: 0 -0.75rem;
  }
  & small {
    white-space: nowrap;
  }
  &__card {
    margin-bottom: 1rem;
    max-width: 100% !important;
    &__border {
      border-color: #ba8318 !important;
      border-radius: 4px !important;
      border-width: 2px !important;
      margin-bottom: 1rem;
    }

    &__edit-email {
      padding-top: 0.5rem;
      cursor: pointer;
      text-decoration: underline;
      font-size: 0.9rem;
      color: var(--primary) !important;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__note {
      font-style: italic;
      padding-top: 1rem;
    }

    &__family {
      padding: 1rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
  }
  &-description {
    &__text {
      font-size: large;
    }
  }
  &__top-bar {
    display: flex;
    justify-content: space-between;
  }
  &__sub-text {
    @media (max-width: 1170px) {
      background-color: var(--bs-card-cap-bg);
    }
    font-size: 1.1rem;
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-bottom-right-radius: var(--bs-card-border-radius);
    border-bottom-left-radius: var(--bs-card-border-radius);
  }
  &__grid {
    display: grid;
    grid-template-areas: "header information newPackage effective discard";
    grid-template-columns: 140px 1fr 1fr 1fr 1fr [end];
  }
  &__grid_digital {
    display: grid;
    grid-template-areas: "header information";
    grid-template-columns: 140px 1fr;
  }
  &__header {
    grid-area: header;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid #5e5e5e1f;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 12px;
    .icon-text {
      font-size: 0.9rem;
      text-transform: uppercase;
    }
    .name-plan {
      text-align: left;
      word-spacing: 30000px;
    }
  }
  &__digital_information {
    min-height: 80px;
    grid-area: information;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    span {
      height: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 0.9rem;
      width: 100%;
    }
    * {
      margin: 0;
    }
  }
  &__information {
    min-height: 80px;
    grid-area: information;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-self: center;
    span {
      height: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 0.9rem;
      width: 100%;
    }
    * {
      margin: 0;
    }
  }
  &__information-long {
    min-height: 80px;
    grid-row-start: information;
    grid-column-start: information;
    grid-row-end: effective;
    grid-column-end: effective;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-self: center;
    span {
      height: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 0.9rem;
      width: 100%;
    }
    * {
      margin: 0;
    }
  }
  &__new-package {
    grid-area: newPackage;
    min-height: 80px;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-self: center;
    span {
      height: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 0.9rem;
      width: 100%;
    }
    * {
      margin: 0;
    }
    &__arrow {
      align-self: center;
      margin-right: 25px;
      color: green;
    }
  }
  &__effective-date {
    grid-area: effective;
    min-height: 80px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    align-self: center;
    * {
      margin: 0;
    }
  }
  &__discard {
    @media (max-width: 1170px) {
      justify-content: center;
    }
    min-height: 80px;
    grid-area: discard;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    p {
      align-self: center;
      cursor: pointer;
      color: red;
    }
    &__back-arrow {
      padding-bottom: 15px;
      cursor: pointer;
      margin-right: 15px;
      // margin-top: 4px;
      width: 8%;
      align-self: center;
      color: red;
    }
  }
  &__discard-disclaimer {
    background: #e8f6ff; /* TODO - determine where this color should be incorporated into the theme, it appears new. */
    border: 0.1rem solid #0171b3;
    border-radius: 0.25rem;
    color: #0071b3;
    font-style: italic;
    font-size: 0.875rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  &__underage {
    background: #fcf3cd;
    color: #856404;
    margin-bottom: 1rem;

    &__header {
      font-weight: bold;
    }

    &__list li {
      list-style-type: disc;
    }
  }

  &__footer {
    border-bottom-right-radius: var(--bs-card-border-radius);
    border-bottom-left-radius: var(--bs-card-border-radius);
    @media (max-width: 1170px) {
      background: none;
      display: block;
      border: none;
    }
    .button-container {
      width: 30%;

      @media (max-width: 1170px) {
        width: 90%;
        display: flex;
        justify-content: center;
      }
    }
    .download-card-button {
      width: 100%;
    }
    .print-card-button {
      width: 100%;
    }
    align-items: center;
    background: var(--secondary);
    display: flex;
    padding: 0.5rem 1rem;

    > * {
      margin: 0.5rem;
    }

    &__action {
      font-size: 0.875rem;
      font-weight: bold;
      margin-left: auto;
      align-self: center;
      cursor: pointer;
      color: red;

      display: flex;

      div {
        padding: 0 0.5rem;
      }

      div + div {
        border-left: 0.125rem solid var(--gray);
      }
    }
  }
  &__svg {
    fill: var(--primary);
  }
  &__information-flex {
    h5 {
      font-size: 18px;
    }
  }
  /*&__table {
    border-collapse: collapse;
    tbody {
      border-radius: 8px;
    }
    td {
      border: 1px solid #000000;
    }
    tbody > tr > td {
      padding: 16px;
      width: 85%;
    }
    tbody > tr > td:first-child {
      vertical-align: middle;
      text-align: center;
      font-weight: bold;
      width: 15%;
    }
  }*/
  &__remove-add {
    font-size: 14px;
    letter-spacing: 0px;
    color: red !important;
    cursor: pointer;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: right;
    font-weight: bold;
    padding-top: 0.5rem;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1 em;
  height: 1 em;
  padding: 0.25 em;
  color: #000;
  border: 0;
  border-radius: 0.25 rem;
  opacity: 0.5;
}
.account-information {
  h1 {
    font-weight: 300;
  }
}

.edit-plan {
  .edit-plan-column {
    padding: 0;
    float: right;

    @media (max-width: 1170px) {
      float: none;
      margin-bottom: 16px;
      width: 90%;
    }
  }
  &__primary {
    &__body {
      background: var(--secondary);
    }
  }
  &__back {
    text-decoration: none !important;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    min-width: 0;
    margin-bottom: 8px;
    color: var(--primary);
    span {
      margin-left: 4px;
      font-weight: bold;
    }
  }
  &__summary {
    min-width: 330px;

    &__bg {
      background-color: #fff !important;
    }

    @media (max-width: 1170px) {
      min-width: 90%;
    }
  }
  .check-circle-fill {
    fill: #67c167;
    width: 16px;
    margin-left: 0.25rem;
  }
  &__text {
    font-size: 0.9rem;
  }
  &__taxes_disclaimer {
    font-size: 0.8rem;
    color: #666666;
  }
  &__discard-button {
    background-color: white !important;
    border-color: white !important;
    color: red !important;
    width: 100%;
  }
}

.Toastify__toast {
  padding: 0;
  border-radius: 3px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast--success {
  background: white !important;
  color: var(--success);
  border-left: 14px solid var(--success);
  padding-left: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  font-weight: bold;

  button {
    color: var(--success);
  }
  .icon-p {
    padding-top: 1px;
  }
}

.Toastify__toast--error {
  background: white !important;
  color: #a02020 !important;
  border-left: 14px solid #a02020 !important;
  padding-left: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  font-weight: bold;

  button {
    color: #3279b9;
  }

  .icon-p {
    padding-top: 1px;
  }
}
.Toastify__toast--success::before {
  color: white;
  text-align: center;
  background: #82b265;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-top: 5px;
}

.bg-col {
  width: 100%;
  // margin-left: -36px !important;
  // margin-top: -35px !important;
}

.bg-details {
  width: 106% !important;
  margin-left: -36px !important;
}

.edit-profile {
  span {
    font-size: 18px;
    font-weight: bold;
  }
  &__names {
    @media (max-width: 768px) {
      width: 80%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1440px) {
      width: 60%;
    }
  }
  &__title {
    font-size: 12px;
    font-family: Roboto;
    color: rgb(102, 102, 102);
    // margin-left: 3px;
  }
  &__title2 {
    font-size: 12px;
    font-family: Roboto;
    color: rgb(102, 102, 102);
    margin-left: -3px;
  }
  &__input {
    margin-left: -7px;
    margin-top: -7px;
  }
  &__input2 {
    margin-top: -7px;
    & a {
      color: var(--primary) !important;
    }
  }
  &__input3 {
    margin-left: 7px;
  }

  .form-control:disabled {
    background-color: #f5f5f5;
    color: #333333;
  }
  p {
    font-size: 14px;
    padding: 7px 0;
    font-family: Roboto;
  }
  form {
    margin-left: 7px;
  }
}

.change-password {
  p {
    font-size: 14px;
    padding: 7px 0;
    font-family: Roboto;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
  }
  &__input {
    border: 1px solid #ddd;
    width: 33%;
  }
  &__show-hide-password {
    cursor: pointer;
    position: absolute;
    top: 20%;
    left: 28%;
    color: #1099d6;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    &__input {
      border: 1px solid #ddd;
      width: 100%;
    }
    &__show-hide-password {
      cursor: pointer;
      position: absolute;
      top: 20%;
      left: 94%;
      color: #1099d6;
      font-size: 12px;
    }
  }
}

.activity-log-tile {
  span {
    font-size: 10px;
    color: #666666;
    font-weight: bold;
    margin: 0 70px 0 0;
  }
  p {
    font-size: 16px !important;
  }
}

.activity-log-tile-card {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  align-items: center;
}

.extra-margin {
  margin-top: 5px;
}
.myPlan-tile {
  font-family: Roboto;
  padding: 0.5rem;
  a {
    text-decoration: none;
  }
  h2 {
    color: #333;
    font-weight: lighter;
    font-size: 24px;
    line-height: 32px;
  }
  span {
    font-size: 14px;
  }
  .title {
    font-size: 12px;
    font-weight: bold;
  }
  .btn {
    // border-radius: 17px;
    margin-right: 11px;
    padding: 6px 27px;
    min-width: 137px;
    @media (max-width: 425px) {
      min-width: 96px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-around;
  }

  .checkout-card {
    transition: all 0.2s;
  }
}

.myPlan-tile-card {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.home-gym {
  h5 {
    font-size: 26px;
    font-weight: lighter;
  }
  h6 {
    font-weight: bold;
    font-size: 26px;
  }
  &__content {
    height: 100%;
    max-height: 200px;
    margin-top: -50px;
  }
  &__icon {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.no-homeGym {
  &__locations {
    background: #dcedf6;
    padding: 13px 20px;
    h3 {
      color: #35627c;
      font-size: 14px;
      font-weight: bold;
    }
    p {
      color: #35627c;
      font-size: 14px;
    }
  }
}

.checkout-card {
  transition: all 0.2s;
  .no-plan {
    h3 {
      font-size: 26px;
      font-weight: lighter;
    }
  }
}
.billing-table {
  --button-width: none;
  border-color: transparent;
  & > :not(:first-child) {
    border-top: none;
  }
  tbody {
    border-top: none;
  }
}
.payment-widget {
  &__loading {
    display: flex;
    justify-content: center;
    margin: 12px 0px;
  }
  &__card {
    box-shadow: 0px 2px 4px #757575;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    span {
      font-size: 00.8rem;
      margin: -1;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    span {
      font-size: 0.9rem;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__default {
    color: #82b265;
    font-size: 1rem !important;
  }
  &__default::before {
    content: "\2713";
    display: inline-block;
    color: #82b265;
    padding: 0 4px 0 0;
  }

  &__button {
    background-color: transparent !important;
    border: none;
    padding: 0px;
    min-width: 0px;
    margin-left: 12px;
  }
  &__button:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &__button:focus {
    outline: none;
    box-shadow: none;
  }
  &__button:hover {
    background-color: inherit;
    color: inherit;
  }

  @media (max-width: 576px) {
    &__button {
      font-size: 0.9rem;
    }
    &__body {
      span {
        font-size: 0.8rem;
      }
    }
    &__default {
      font-size: 0.9rem !important;
    }
  }

  &__success,
  &__success:active {
    opacity: 1;
    color: var(--primary) !important;
  }

  &__error,
  &__error:active {
    opacity: 1;
    color: var(--red) !important;
  }
}
.csr-banner {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #c7dcff;
  color: #363636;
  border-bottom: rgba(0, 0, 0, 0.16) 1px solid;
  h5,
  h6 {
    color: #363636;
  }
  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 80px;
    padding: 0 12px;
    text-align: center;
    margin-right: 16px;
  }
  &__container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    span {
      color: #333333;
      font-size: 1.1rem;
    }
  }
  &__container > * {
    margin-right: 12px;
  }
}

.main-layout {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.version-banner {
  width: 100%;
  background-color: lightgoldenrodyellow;
  color: black;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-success {
  background-color: #449d44;
  border-color: #449d44;
  padding: 10px 5px 10px 5px;
}

.success-banner-container {
  padding-left: 5px;
  color: #ffffff;

  .success-banner-p1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 -21px 30px;
  }
  .success-banner-p2 {
    font-size: 14px;
    margin: -13px 0 0 32px;

    a {
      color: #ffffff;
    }
  }
  .fa-check-circle {
    color: white;
  }
  .sucess-banner-icon {
    font-size: 25px;
    margin-right: 5px;
    fill: white !important;
  }
}

.setHomeGymModal {
  padding: 16px;
  &__button {
    width: 100%;
    color: white;
    margin: 30px 0;
    display: block;
    padding: 15px;
    text-align: center;
    transition: background-color 0.2s cubic-bezier(0, 0.2, 0.8, 1);
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    background-color: #0078bf;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
  }

  &__details {
    text-align: center;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold !important;
  }
  &__subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #666;
  }
}

.locations-homeGym {
  font-size: 14px;
  font-weight: bold;
}
.locations-setHomeGym {
  position: absolute;
  top: 0px;
  left: -7px;
  margin-top: 5px;
  span {
    display: inline-flex;
    margin-top: 17px;
    margin-left: -18px;
  }
  svg {
    margin-top: -8px;
    margin-right: 2px;
  }
  &__icon {
    top: 13px;
    right: 62px;
    width: 15px;
  }
  &__text {
    top: 16px;
    font-size: 12px;
    left: 0px;
    width: 34px;
    white-space: nowrap;
    font-style: italic;
    color: #0d2674;
    font-weight: bold;
  }
}
.btn-secondary {
  color: white;
  background-color: var(--primary);
}

.hide-card-img {
  &__left {
    @media (min-width: 413px) {
      width: 50%;
      padding: 0;
    }
  }
  &__right {
    @media (min-width: 413px) {
      width: 50%;
      padding: 0;
    }
  }
  &__small {
    display: block;
    @media (min-width: 413px) {
      display: none;
    }
  }
  @media (max-width: 413px) {
    display: none;
  }
}
// How It Works Section
.feature-base {
  &.hiw-header {
    .slanted-feature-right::before {
      content: "";
      width: 120%;
      height: 90%;
      position: absolute;
      right: 135px;
      top: 37px;
      background: var(--slant);
      opacity: 0.75;
      z-index: -1;
      -webkit-transform: skew(-25deg, 0deg);
      transform: skew(-25deg, 0deg);

      @media only screen and (max-width: 1290px) {
        width: 160px;
      }

      @media only screen and (max-width: 1130px) {
        display: none;
      }
    }
    .learn-more {
      display: none;
    }
    .feature-content {
      h1 {
        margin-bottom: 2rem;
      }
      p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 2rem; // we need to delete the paragraph below in contentful
      }
    }
    @media (max-width: 992px) {
      .feature-base__background {
        background-color: var(--walmartYellow);
        background-image: none !important;
      }
      .feature-content {
        max-width: 100%;
        padding: 4rem;
      }
      .slanted-feature-right {
        padding: 0;
        width: 100%;
      }
      .feature-base__child-container {
        justify-content: center;
      }
    }
  }
  @media (max-width: 992px) {
    .slanted-feature-right::after {
      transform: none;
    }
  }
}

.tab-pane {
  padding: 2rem;
  .grid {
    .learn-more {
      display: none;
    }
    .grid__stepper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      .grid__stepper {
        display: none;
      }
      .feature-base__background {
        visibility: hidden;
      }
      .feature-base__child {
        flex: auto !important;
      }
      .feature-base--xs {
        height: auto;
      }
    }
  }
  .feature-content.large {
    max-width: 80%;
  }
}

.how-it-works-nav-container {
  .nav-item {
    a {
      padding: 0.5rem 50px;
    }
  }
  @media (max-width: 992px) {
    .nav-container-header {
      padding: 1rem;
    }
    .nav-tabs {
      margin: 0;
      justify-content: space-around;
      align-items: center;
      flex-wrap: initial;
      .nav-item {
        a {
          font-size: 14px;
          padding: 0.25rem;
        }
      }
    }
  }
}

.bottom-feature {
  @media (min-width: 992px) {
    padding: 4em 10em;
  }
}
// *******

.payment-methods {
  & .payment-widget__card,
  & .payment-widget__loading {
    min-width: 300px;
    max-width: 460px;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &__add-new {
    margin-top: 12px;
  }
}

.collapse-header {
  color: var(--primary);
  font-weight: bold;
  &__content {
    padding-left: 16px;
    padding-bottom: 8px;
    font-size: 0.9rem;
  }
  &__content:empty {
    padding: 0px;
  }
}
.promo-collapse-header {
  color: var(--primary);
  font-weight: bold;
  &__content {
    padding-left: 16px;
    // padding-bottom: 8px;
    font-size: 0.9rem;
  }
  &__content:empty {
    padding: 0px;
  }
}
.payment-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(85, 85, 85);
}

.sub-footer {
  margin-top: -8px;
  margin-left: 1px;
}

.unstyled-button {
  border: none;
  padding: 0 !important;
  margin: 0 !important;
}

.checkout-modal {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-bottom: 12px;
  }
  &__title {
    margin-bottom: 24px;
  }
  &__subheader {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 4px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    span {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}
.eligibility-toast {
  &__card {
    width: 100%;
    min-height: 80px;
    border-left: 12px #c28100 solid !important;
    margin-bottom: 24px;
  }
  &__title {
    color: #c28100;
    font-size: 1.1rem;
    span {
      padding-left: 8px;
    }
  }
  &__body {
    padding: 1rem;
  }
  @media (max-width: 992px) {
    p {
      font-size: 0.8rem;
    }
  }
  &__body {
    p > a {
      // color: #0d6efd !important;
    }
  }
}

.locationDetailsTabs {
  position: absolute;
  right: 30px;
  top: -15px;
  &__marker {
    color: #82b265;
  }
  &__eliteText {
    color: #82b265;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: bold;
  }
  &__homeGymText {
    top: 16px;
    font-size: 12px;
    left: 0px;
    width: 74px;
    white-space: nowrap;
    font-style: italic;
    color: #0d2674;
    font-weight: bold;
    display: inline;
  }
}

.digital-upgrade-banner {
  padding: 12px;
  width: 100%;
  background-color: var(--primary);
  color: white;
  margin-bottom: 16px;
  border-radius: 4px;
  h5 {
    color: white;
  }
  i {
    font-size: 14px;
  }
  a {
    float: right !important;
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
  &__button {
    color: var(--primary) !important;
    background: white !important;
  }
}

.logout-button {
  font-size: 12px !important;
  min-width: 0px;
  text-align: center;
  color: red;
  text-decoration: none;
  font-size: 1rem;
  width: min-content;
  margin: 0px;
  margin-bottom: 8px;
  white-space: nowrap;
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}

.migration-feature-list {
  padding-left: 0;
  li::before {
    content: "✔";
    font-size: 16px;
    line-height: 16px;
    color: #82b265;
    text-align: left;
    border-radius: 50%;
    padding-right: 12px;
  }
}
.not-found-container {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  // max-width: 456px;
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 456px;
    p,
    h1 {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
    }
    button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__button {
      color: white;
    }
    &__header {
      margin-top: 30px;
      margin-bottom: 10px;
      font: normal normal bold 20px/28px Roboto;
      font-size: 26px;
      overflow: hidden;
      white-space: nowrap;
      // text-decoration: ;
    }
    &__phone {
      font-size: 26px;
      color: #1099d6;
    }
  }
  &__SomethingWentWrongImg {
    width: 55%;
  }
}

.creditBalance {
  .title {
    background-color: #e4ebde;
  }
  &__body {
    background-color: #f5f5f5;
    font-size: 13px;
    font-style: italic;
  }
  h6 {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    span {
      color: #008800;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.account-suspension-banner {
  border-color: #ba8319;
  border-radius: 4px;
  border-width: 0 0 0 13px;
  background-color: #fff;
  .alert-heading {
    font: normal normal bold 18px/24px Roboto;
    color: #ba8319;
    span {
      margin-right: 10px;
    }
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
}
.no-active-fitness-package {
  border: 1px solid #946914;
  border-radius: 4px;
  background-color: #fcf7e4;
  padding-left: 4px;
  svg {
    height: 14px;
    color: #946914;
    margin-right: 3px;
  }
  u {
    cursor: pointer;
  }
  span {
    font: normal normal normal 14px/20px Roboto;
    color: #946914;
    opacity: 1;
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
}
.location-waitlist-banner {
  font-size: 14px;
  background-color: #fcf7e4;
  border-color: #946913;
  border-radius: 1px;
  color: #946914;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: -15px;

  .waitlist-icon {
    padding: 0px;
    margin: 0px;
    color: #946914;
    font-size: 14px;
  }
  .waitlist-title {
    padding: 0px;
    margin: 0px;
  }

  .waitlist-body {
    padding: 0px;
    margin: 0px;
  }
}

.blue365-banner {
  .alert-heading {
    margin-bottom: 0px;
  }
  font-size: 14px;
  background-color: #e8f6ff;
  border-color: #0171b3;
  border-radius: 1px;
  color: #0071b3;
  padding: 0px 10px;
  margin-top: 10px;
  margin-bottom: -15px;
  margin-right: 47px;

  .blue365-icon {
    padding: 0px;
    margin: 0px;
    color: #0071b3;
    font-size: 14px;
  }
  .blue365-title {
    font-weight: normal;
    padding: 0px;
    margin: 0px;
  }

  .blue365-body {
    padding: 0px;
    margin: 0px;
  }
}

.discard-cancel-banner {
  border-color: #ba8319;
  border-radius: 5px;
  border-width: 0 0 0 13px;
  background-color: #fff;
  box-shadow: 0px 2px 8px #00000029;
  margin: 0 15px 15px 0;
  .alert-heading {
    font: normal normal bold 18px/24px Roboto;
    color: #ba8319;
    span {
      margin-right: 10px;
    }
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
  &__discard-button {
    color: #8d6313;
    border: none;
    max-width: fit-content;
    padding-left: 0px;
  }
  &__back-arrow {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
}
.account-overdue-banner {
  border-color: var(--red);
  border-radius: 4px;
  border-width: 0 0 0 13px;
  background-color: #fff;
  .alert-heading {
    font: normal normal bold 18px/24px Roboto;
    color: var(--red);
    span {
      margin-right: 10px;
    }
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
}

.account-update-success-banner {
  margin: 3% 0px;
  border-color: #07bc0c;
  border-radius: 4px;
  border-width: 0 0 0 13px;
  background-color: #fff;
  .alert-heading {
    font: normal normal bold 18px/24px Roboto;
    color: #82b265;
    span {
      margin-right: 10px;
    }
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
}
.account-update-failed-banner {
  margin: 3% 0px;
  border-color: #bf1a1a;
  border-radius: 4px;
  border-width: 0 0 0 13px;
  background-color: #fff;
  .alert-heading {
    font: normal normal bold 18px/24px Roboto;
    color: #bf1a1a;
    span {
      margin-right: 10px;
    }
  }
  &__body {
    font: normal normal normal 14px/19px Roboto;
  }
}

.navbar-nav {
  a {
    margin-right: 50px;
  }
}

.informationBanner {
  background-color: var(--informationBanner);
  width: 100%;
  height: 45px;
  padding: 10px 0;
  display: block;
  text-align: center;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #ffffff;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.popover {
  max-width: 500px !important;
  @media screen and (max-width: 1024px) {
    max-width: 80vw !important;
    word-wrap: break-word;
    img {
      max-width: 100% !important;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
  img {
    max-width: 380px;
  }
}

.maintenance-wrapper {
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  margin: 0 auto;
  width: 960px;
  position: relative;
  background-color: blue;
  .ltBox {
    top: 35px;
    border-right: 3px #eb3300 solid;
    border-bottom: 3px #eb3300 solid;
    height: 400px;
    width: 477px;
    position: absolute;
    .lt-content {
      text-align: right;
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 15px;
    }
  }
  .rtBox {
    a {
      color: gray;
    }
    top: 235px;
    left: 500px;
    border-left: 3px #e2e2e2 solid;
    border-top: 3px #e2e2e2 solid;
    height: 400px;
    width: 477px;
    position: absolute;
    .rt-content {
      padding: 15px;
    }
  }
}

.a-link {
  color: #0071ce !important;
}

.anchor {
  display: block;
  height: 80px; /*same height as header*/
  margin-top: -80px; /*same height as header*/
  visibility: hidden;
}

// .nav-link,
// .navbar-light .navbar-nav .nav-link {
//   color: black;
// }
// .nav-link,
// .navbar-light .navbar-nav .nav-link:hover {
//   color: var(--primary);
// }

// .nav-link,
// .navbar-light .navbar-nav .nav-link.active {
//   color: var(--primary);
// }

.navbar-nav .nav-item {
  margin: 0px !important;
}

.check-button {
  .btn {
    color: var(--primary);
  }

  background-color: white;
  // color: #0071dc;
  margin: 0 5px 0 0;
  width: 140px;
  height: 40px;
  position: relative;
  white-space: nowrap;
  margin: 1rem auto 0;
  background: white;
  border-radius: 5px;
}

.check-button label,
.check-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:hover {
    cursor: pointer;
  }
}

.check-button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.check-button label {
  border: 1px solid var(--primary);

  &:hover {
    border: 1px solid var(--primary);
  }
}

.check-button input[type="radio"]:checked + label {
  color: #449d44;
  border-radius: 4px;
  border-color: #449d44;
  display: inline-block;
}

.check-button label {
  line-height: 1.8em;
  border: 1px solid var(--primary);

  &:hover {
    border: 1px solid var(--primary);
  }
}

.check-button-digital-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-right: 20px;
  padding-left: 35px;
  min-height: 100px;
}

.check-button-digital {
  .btn {
    color: var(--primary);
    border-color: var(--primary);
  }
  background-color: white;
  width: 100%;
}

.check-button-digital input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
}

.check-button-digital input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.check-button-digital input[type="radio"]:checked + label {
  color: #449d44;
  border-color: #449d44;
  border-radius: 4px;
  display: inline-block;
}

.check-button-digital label {
  cursor: pointer;
  width: 100%;
}

.num-des {
  display: none;
}

.modal-header {
  .modal-title {
    align-items: flex-start;
    color: #000;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;
  }
}

.modal-family ~ .modal .modal-dialog .modal-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 80%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  outline: 0;
}

.modal-header-family-member {
  margin-top: -2rem;
  font-size: 0.8rem;
}

.modal-body-family-member {
  padding-bottom: 0;
}

.modal-footer .modal-footer-family-member {
  &__button {
    font-size: 15px !important;
    &__add {
      color: var(--modalButton);
      background-color: #ffffff;
      font-size: 15px !important;
      &:hover {
        background-color: var(--hoverButtonColor);
        transition: all 0.3s ease;
        border-color: var(--primary);
        color: var(--primary);
        cursor: pointer;
      }
    }
  }
}

.modal-confirmed-family-close {
  cursor: pointer;
  position: relative;
  top: -90%;
  right: -50%;

  &::before,
  &::after {
    width: 1rem;
    height: 2px;
    background-color: var(--light);
    display: inline-block;

    content: "";
    position: absolute;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &::before {
    transform: rotate(135deg);
    right: 10%;
  }

  &::after {
    transform: rotate(-135deg);
    right: 10%;
  }
}
.modal-add-family-close {
  cursor: pointer;
  position: relative;

  &::before,
  &::after {
    width: 1rem;
    height: 2px;
    background-color: var(--legalDarkBlue);
    display: inline-block;

    content: "";
    position: absolute;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &::before {
    transform: rotate(135deg);
    top: -30%;
  }

  &::after {
    transform: rotate(-135deg);
    top: -30%;
  }
}

.alert-info {
  font-size: 0.8rem;
  margin: 0 0.9rem;

  &__flex {
    display: inline-block;
    height: 15px;
    line-height: 1.3;
  }

  &__icon {
    position: relative;
    top: -2px;
    margin-right: 0.3rem;
  }

  &__number {
    font-weight: 400;
  }
}

.next-payment-info-loading {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 100%;
}

.mt-minus-half-rem {
  margin-top: -0.5rem;
}
.mt-minus-zero2-rem {
  margin-top: -0.02rem;
}

.primary-member-alert-info {
  margin-top: 1rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-color: var(--primary);
  font-style: italic;
}

.plan-summary-promo-code {
  font-size: 0.9rem;
  color: var(--primary);
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.disabled-links {
  pointer-events: none;
  opacity: 0.7;
}

body .auth-payroll-modal .modal-dialog {
  max-width: 600px !important;
  height: 80% !important;
  overflow-y: auto !important;
}

.auth-payroll-modal {
  &size {
    -ms-flex-align: baseline;
  }
  &__button-container {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
}
.auth-nopayroll-modal {
  &size {
    -ms-flex-align: baseline;
  }
  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}

.paymentMethodCard:hover {
  transform: scale(1.03);
  box-shadow: 0px 10px 15px -5px var(--primary) !important;
}

.my-plan-tile {
  -ms-flex-align: baseline;
  display: flex;
  &__button-container {
    justify-content: space-around;
    display: flex;
  }
  justify-content: space-between;
}
.current-email-text {
  color: grey;
  font-size: 16px;
  margin-left: -0.2rem;
}

.agree-terms-modal {
  .modal-header {
    background: var(--primary);
    .modal-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__h2 {
        color: white;
        text-align: center;
        font-size: 25px;
      }
    }
  }
  .modal-body {
    &__h2 {
      text-align: center;
      font-size: 15px;
    }
    &__label {
      display: flex;
      justify-content: center;
      font-size: 12px;
      flex-direction: row;
    }
    .required-field-marker {
      color: #d9534f;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;

    .line {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #dee2e6;
      margin-bottom: 15px;
    }
  }
  &__button {
    background-color: var(--primary);
    width: 48%;
  }
}

.agree-terms-modal {
  .modal-header {
    background: var(--primary);
    .modal-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__h2 {
        color: white;
        text-align: center;
        font-size: 25px;
      }
    }
  }
  .modal-body {
    &__h2 {
      text-align: center;
      font-size: 15px;
    }
    &__label {
      display: flex;
      justify-content: center;
      font-size: 12px;
      flex-direction: row;
    }
    .required-field-marker {
      color: #d9534f;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;

    .line {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #dee2e6;
      margin-bottom: 15px;
    }
  }
  &__button {
    background-color: var(--primary);
    width: 48%;
  }
}

.modal-edit-family-close {
  cursor: pointer;
  position: relative;
  margin-top: 25px;
  margin-right: 25px;

  &::before,
  &::after {
    width: 1rem;
    height: 2px;
    background-color: #000000;
    display: inline-block;

    content: "";
    position: absolute;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &::before {
    transform: rotate(135deg);
    top: -30%;
  }

  &::after {
    transform: rotate(-135deg);
    top: -30%;
  }
}

.alert-info {
  font-size: 0.8rem;
  margin: 0 0.9rem;

  &__flex {
    display: inline-block;
    height: 15px;
    line-height: 1.3;
  }

  &__icon {
    position: relative;
    top: -2px;
    margin-right: 0.3rem;
  }

  &__number {
    font-weight: 400;
    color: var(--bs-link-color);
  }
}
.grayed-out {
  opacity: 0.5;
  pointer-events: none;
}

.invalid-feedback-dob {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  @media only screen and (min-width: 768px) {
    margin-top: -12px;
    margin-left: 192px;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 272px;
  }
  @media only screen and (min-width: 1200px) {
    margin-left: 167px;
  }
  @media only screen and (min-width: 1400px) {
    margin-left: 197px;
  }
}

.csr-tip {
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  background-color: var(--infoBlue);
  width: 50%;
  padding: 0.5rem;
  margin-top: 1rem;
  font-weight: 500;
}
.modal-container .modal-dialog .modal-content {
  padding: 1rem;
}

.modal-container-title {
  color: var(--dark) !important;
  font-weight: bold !important;
  margin-bottom: -15px;
  margin-left: 15px;
}

.modal-container-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.registration-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 4rem;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  &__header-container {
    margin-bottom: 1rem;
  }

  &__fields-container {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .logo {
    width: 10rem;
    margin: auto;
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
  }
  .box {
    display: flex;
    flex-direction: Column;
    justify-content: center;
    margin: auto;
    padding: 1.5rem;
    width: 30rem;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #e0e0e0;
  }
  .title {
    font-size: 1.5rem;
  }
}

.edit-plan-link {
  text-decoration: "underline";
  color: var(--primary);

  &:hover {
    text-decoration: none !important;
    color: var(--primary) !important;
  }
}

.cancel-subscription {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 100px;

  & p {
    font-weight: bold;
  }

  &:hover {
    background-color: #e9ecef;
  }
}

.discard-subscription {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 100px;
  margin-left: auto;

  & p {
    font-weight: bold;
  }

  &:hover {
    background-color: #e9ecef;
  }
}

.disabled-icon {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.edit-profile .optional-field {
  margin-top: -0.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
}

.edit-plan-link {
  text-decoration: "underline";
  color: var(--primary);

  &:hover {
    text-decoration: none !important;
    color: var(--primary) !important;
  }
}

.edit-profile .optional-field {
  margin-top: -0.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
}

.edit-plan-link {
  text-decoration: "underline";
  color: var(--primary);

  &:hover {
    text-decoration: none !important;
    color: var(--primary) !important;
  }
}

.edit-profile .optional-field {
  margin-top: -0.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
}

.invalid-select-container {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-select {
  font-size: 0.875em;
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
}

.card-promo-code {
  margin-top: 2rem;
  &__header {
    font-weight: 700 !important;
    font: normal normal bold 14px/19px Roboto;
    margin-bottom: 1rem;
  }
  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.card-no-rounded-border {
  border-radius: 0;
  &__new-plan {
    border-left: none;
    border-right: none;
  }
  &__due-now {
    border: none;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}

.no-payment-on-file {
  color: rgb(101, 101, 101);
  border: 1px solid rgb(224, 224, 224);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;

  &__text {
    font-weight: bold;
  }

  &__note {
    font-size: 0.8rem;
  }
}

.package-card-label {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
}

.addMemberModal {
  &__row {
    &__name {
      font-weight: bold;
    }
    &__dob {
      color: #6f6f6f;
      margin-left: 26px;
      font-size: 14px;
      font-style: italic;
    }
  }
}

.manage-members {
  margin-left: -10px !important;

  h3 {
    font-size: 25px;
    font-weight: bold;
  }
}

.underage-banner-heading {
  font-size: 16px;
  font-weight: bold;
  color: #ba8319;
}
.underage-banner-subheading {
  font-size: 14px;
}
.underage-bullet-list {
  font-size: 13px;
  li::before {
    content: "\25CF";
    color: #000000;
    font-size: 15px;
    margin-right: 15px;
    display: inline-block;
  }
}

.luxury-gym {
  &__content {
    margin: 2rem;
    height: 205px;
  }
  &__button,
  button {
    border-radius: 5px;
    width: 90%;
    padding: 6px 20px;
  }
  &__bug {
    background: #e0ecd8 0% 0% no-repeat padding-box;
    border-radius: 30px;
    font-size: 12px;
    &__text {
      margin: 1rem;
    }
  }
  &__next {
    background: #eaeaea 0% 0% no-repeat padding-box;
  }
  &__gym-name {
    font-size: 20px;
    margin: 5px;
  }
  &__gym-details {
    text-decoration: underline;
    cursor: pointer;
  }
  &__gym-disclaimer {
    font: italic normal normal 13px/18px Roboto;
    margin-bottom: 10px;
    letter-spacing: 0px;
    color: #6a6a6a;
  }
  &__underage-disclaimer {
    margin-top: 2rem;
  }
  &__link-icon {
    top: 5px;
    width: 8px !important;
    margin-left: 3px;
  }
  &__direction-link {
    color: #0071ce;
    cursor: pointer;
    &:hover {
      color: #0071ce !important;
    }
  }
  &__next-arrow {
    margin: 1rem;
  }
  &__footer {
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }
}

.add-family {
  &__disclaimer {
    font: normal normal normal 12px/22px Roboto;
    margin-top: 1rem;
  }
  &__dob {
    font: italic normal normal 14px/24px Roboto;
    color: #6f6f6f;
    margin-left: 0.75rem;
  }
  &__hr {
    border: 1px solid #0000001f;
    opacity: 1;
    margin-top: 0.5rem;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.underage-dependent__card {
  border: 2px solid #ba8318;
}

.invalid-email-text {
  color: #dc3545 !important;
  font-size: 10px;
}
.activity_log_button {
  width: 90%;
}
.luxury-gym-note {
  margin-top: 1rem !important;
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
}

.modal-90w {
  max-width: 50% !important;
  min-width: 30% !important;
}

label.mutually-well {
  box-shadow: 0px 3px 6px #00000040;
  border-radius: 10px;
  display: block;
  width: 100%;
  max-width: 634px;
  margin: 0px auto;
  position: relative;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 634 369' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg id='Mask_Group_103'%3E%3CclipPath id='_clip1'%3E%3Cpath d='M634,10L634,358.998C634,364.517 629.519,368.998 624,368.998L10,368.998C4.481,368.998 0,364.517 0,358.998L0,10C0,4.481 4.481,0 10,0L624,0C629.519,0 634,4.481 634,10Z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23_clip1)'%3E%3Cg id='blob-4' transform='matrix(-0.978,-0.208,0.208,-0.978,866.595,584.03)'%3E%3Cpath d='M369.812,33.351C420.23,49.111 479.241,61.3 491.312,96.459C503.373,132.036 468.498,190.582 457.973,243.465C447.037,295.941 460.451,342.753 459.473,402.475C458.495,462.197 424.051,508.047 381.373,544.261C338.695,580.475 287.802,606.634 237.407,576.261C187.024,545.472 122.228,517.461 70.894,490.033C19.56,462.605 14.047,418.44 3.004,391.184C-8.026,363.511 9.837,319.8 25.418,268.034C40.999,216.268 21.951,173.365 30.266,137.24C38.157,101.127 73.823,72.198 112.766,46.94C151.709,21.682 194.364,0.5 236.378,-0.215C278.405,-1.345 319.819,17.58 369.812,33.351Z' style='fill:url(%23_Linear2);fill-rule:nonzero;'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='_Linear2' x1='0' y1='0' x2='1' y2='0' gradientUnits='userSpaceOnUse' gradientTransform='matrix(504.608,-241.252,241.252,504.608,14.9076,360.14)'%3E%3Cstop offset='0' style='stop-color:rgb(80,174,189);stop-opacity:0.31'/%3E%3Cstop offset='1' style='stop-color:rgb(148,63,107);stop-opacity:0.31'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-color: white;

  .card {
    width: auto;
    padding: 16px 16px 16px 16px;
    border: none;
    background-color: transparent;
  }

  .card-body {
    background-color: transparent;
    margin: 16px 0;
    padding: 0;
  }

  .card-text {
    display: flex;
    align-items: center;

    .price {
      margin: 0;
      font-size: 48px;
    }

    .per-month {
      margin: 0 0 0 9px;
      color: #333333;
      font-size: 16px;
    }
  }

  .card-title {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    margin: 0px auto;
  }

  h6 {
    font-size: 20px;
    font-style: italic;
    color: #0071b3;
    line-height: 28px;
  }

  .card-footer {
    position: relative;
    margin-top: 16px;
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  .zuora-pricing-package {
    text-align: left;
  }

  .lgy-gyms {
    border: 2px solid #943f6b;
    border-radius: 5px;
    width: 100%;
    max-width: 366px;
    padding: 8px;

    .lgy-ct {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
  }

  .extras {
    margin-top: 15px;

    p:nth-child(odd) {
      color: #000000;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      margin-left: 60px;
    }

    p:nth-child(even) {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      color: #757575;
      margin-left: 60px;
      padding-bottom: 20px;
    }

    p.des:last-of-type {
      padding-bottom: 0;
    }
  }

  .zuora-icons {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    font-size: 24px;
    color: #50aebd;
    left: 0;
    bottom: -4px;

    .icon {
      width: 46px;
      height: 46px;
      border: 1px solid #50aebd;
      border-radius: 50%;
      text-align: center;
      margin: 12px 0 0;
    }

    svg {
      margin-top: 9px;
    }
  }

  .check-button {
    .btn {
      color: white;
    }

    .fa-check-circle {
      color: white;
      margin-right: 5px;
    }

    width: 232px;
    height: 41px;
    margin: 16px 0 0;
    background-color: #21445c;
  }
}

label.mutually-well-description-only {
  background-image: none;
  .card-title {
    display: none;
  }

  .card-text {
    display: none;
  }

  .card-footer {
    margin-top: 0;
  }

  .lgy-gyms {
    display: none;
  }
}
